import React from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  formKey,
  status,
  subFormKeyIntake,
  education,
  formStatus,
} from "constants/constants";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import {
  setShowPageLoader,
  togglePersonalInfo,
  toggleIntakeEducationalBackground,
  toggleIntakeEmploymentHistory,
  toggleIntakeHealthInfo,
  toggleIntakeHousingSection,
  toggleIntakeImmigrationInfo,
} from "store/actions";

class IntakeEducationalBackground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
    };
  }

  componentDidMount = async () => {
    const { clientData, referenceNote } = this.props;

    try {
      const leadClientData = {
        memberHouseholdId: clientData?.memberHouseholdId,
        uci: clientData.uci,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        middleName: clientData.middleName,
        gender: clientData.gender,
        dateOfBirth: clientData.dateOfBirth,
        relation: "Self",
      };
      const allMembersData = [leadClientData, ...clientData.members];
      this.setState({ allMembers: allMembersData });
    } catch (error) {
      toast.error(error);
    }
  };

  handleSubmit = async values => {
    const {
      clientId,
      togglePersonalInfo,
      toggleIntakeHealthInfo,
      toggleIntakeImmigrationInfo,
      toggleIntakeEducationalBackground,
      personalInfo,
      intakeImmigrationInfo,
      intakeHealthInfo,
      intakeEducationalBackground,
      getClientData,
      setShowPageLoader,
    } = this.props;

    if (clientId) {
      try {
        setShowPageLoader(true);
        const requestBody = {
          type: {
            is_completed: true,
            form_key: formKey.INTAKE,
          },
          educationInformation: {
            type: {
              subForm_key: subFormKeyIntake.EDUCATION,
              formStatus: status.COMPLETED,
            },
            educationDetailsMembers: values.educationDetailsMembers.map(
              educationDetailsMember => ({
                memberId: educationDetailsMember.memberId,
                highestEducation: educationDetailsMember.highestEducation,
              })
            ),
          },
        };
        const response = await axiosPatch(
          `/add-client/intake/${clientId}/education`,
          {
            requestBody: requestBody,
            referenceNotes: values?.referenceNotes,
          }
        );
        if (response?.status) {
          setShowPageLoader(false);
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
          if (intakeEducationalBackground) {
            toggleIntakeEducationalBackground();
          }
          if (intakeHealthInfo) {
            toggleIntakeHealthInfo();
          }
          if (personalInfo) {
            togglePersonalInfo();
          }
          if (intakeImmigrationInfo) {
            toggleIntakeImmigrationInfo();
          }
        } else {
          setShowPageLoader(false);
          toast.error("handleSubmit API error", response?.message);
        }
      } catch (error) {
        toast.error("handleSubmit API error", error);
      }
    }
  };

  render() {
    const { allMembers } = this.state;
    const { intakeData, referenceNote } = this.props;
    var refNote = "";
    if (referenceNote) {
      let latestNote = null;
      for (const note of referenceNote) {
        if (
          note.form === formKey.INTAKE &&
          note.subForm === subFormKeyIntake.EDUCATION &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }

    const initialValues = {
      educationDetailsMembers:
        intakeData?.educationDetailsMembers?.length > 0
          ? allMembers.map(member => {
              const intakeMember = intakeData?.educationDetailsMembers.find(
                intakeMember => intakeMember.memberId === member.memberHouseholdId
              );
              return {
                memberId: member?.memberHouseholdId || "",
                memberName: `${member?.firstName} ${member?.middleName} ${member?.lastName}` || "",
                highestEducation: intakeMember?.highestEducation || "",
              };
            })
          : allMembers.map(member => ({
              memberId: member.memberHouseholdId,
              memberName: `${member.firstName} ${member.middleName} ${member.lastName}`,
              highestEducation: "",
            })),
      referenceNotes: refNote || "",
    };
    const validationSchema = Yup.object().shape({
      educationDetailsMembers: Yup.array().of(
        Yup.object().shape({
          highestEducation: Yup.string().required(
            "Please Select the highest education"
          ),
        })
      ),
    });

    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Education Details</h4>
                        </Col>

                        <Col md="9">
                          {allMembers.map((member, index) => (
                            <div key={`${index}-${member.memberId}`}>
                              <h5 className="form-label mb-2">
                                {member.firstName} {member.middleName}{" "}
                                {member.lastName}
                              </h5>
                              <Label className="form-label">
                                Education details
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="col-md-6 mb-3">
                                <Select
                                  name={`educationDetailsMembers[${index}].highestEducation`}
                                  options={education}
                                  value={education.find(
                                    option =>
                                      option.value ===
                                      values.educationDetailsMembers[index]
                                        ?.highestEducation
                                  )}
                                  onChange={option => {
                                    setFieldValue(
                                      `educationDetailsMembers[${index}].highestEducation`,
                                      option.value
                                    );
                                  }}
                                  className={
                                    errors.educationDetailsMembers?.[index]
                                      ?.highestEducation &&
                                    touched.educationDetailsMembers?.[index]
                                      ?.highestEducation
                                      ? " is-invalid"
                                      : ""
                                  }
                                  placeholder="Select the education"
                                />
                                <ErrorMessage
                                  name={`educationDetailsMembers[${index}].highestEducation`}
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              {index !== allMembers?.length - 1 && (
                                <div className="col-md-16">
                                  <hr></hr>
                                </div>
                              )}
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Reference Notes</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Field
                                name="referenceNotes"
                                as="textarea"
                                className={
                                  "form-control" +
                                  (errors.referenceNotes &&
                                  touched.referenceNotes
                                    ? " is-invalid"
                                    : "")
                                }
                                style={{ resize: "none" }}
                                placeholder="Enter any notes or references"
                              />
                              <ErrorMessage
                                name="referenceNotes"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button type="submit" color="primary">
                            {intakeData?.type?.formStatus ===
                            formStatus.COMPLETED
                              ? dirty
                                ? "Save"
                                : "Edit"
                              : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntakeEducationalBackground));
