import { Form, Formik, Field, ErrorMessage } from "formik";
import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Row, Col, Label, Button, FormGroup } from "reactstrap";
import * as Yup from "yup";
import {
  provincesAndCities,
  stayInfo,
  consent,
  otherSources,
  durationUnit,
  status,
  subFormKey,
  formStatus,
  formKey,
} from "constants/constants";
import SingleSelectWithOther from "components/Common/SingleSelectWithOther";
import Select from "react-select";
import { toast } from "react-toastify";
import { axiosPatch } from "helpers/api_helpers";
import {
  setShowPageLoader,
  toggleGeneralInfo,
  toggleImmigrationInfo,
  toggleInquiryInfo,
} from "store/actions";

const validationSchema = Yup.object().shape({
  receivedSocialAssistance: Yup.string().required(
    "Please Select any one option"
  ),
  socialAssistanceProvince: Yup.string().when("receivedSocialAssistance", {
    is: "true",
    then: Yup.string().required("Please select a province"),
  }),
  receivedSocialAssistanceInMonth: Yup.string().when(
    "socialAssistanceProvince",
    {
      is: "Quebec",
      then: Yup.string().required("Please select any one option"),
    }
  ),
  currentStay: Yup.string().required("Please select your current shelter"),
  currentStayOtherText: Yup.string().when("currentStay", {
    is: "Other",
    then: Yup.string().required("Please enter current shelter"),
    otherwise: Yup.string().notRequired(),
  }),
  duration: Yup.number().required("Please enter a number"),
  durationUnit: Yup.string().required("Please enter the duration unit"),
  otherStaySource: Yup.string().required("Please select your option"),
  otherStaySourceOtherText: Yup.string().when("otherStaySource", {
    is: "Other",
    then: Yup.string().required("Please enter other source"),
    otherwise: Yup.string().notRequired(),
  }),
  consent: Yup.string().required("Please select your consent"),
  consentOtherText: Yup.string().when("consent", {
    is: "Other",
    then: Yup.string().required("Please enter other consent"),
    otherwise: Yup.string().notRequired(),
  }),
});

class InquiryInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        receivedSocialAssistance: false,
        socialAssistanceProvince: "",
        receivedSocialAssistanceInMonth: false,
        durationUnit: "",
        duration: "",
        currentStay: "",
        currentStayOtherText: "",
        otherStaySource: "",
        otherStaySourceOtherText: "",
        consent: "",
        consentOtherText: "",
        shelter: [],
        settlement: [],
        both: [],
        unselectedOptions: "",
        notes: "",
        referenceNotes: "",
      },
      shelterOptions: [],
      settlementOptions: [],
      bothOptions: [],
      selectedSocialAssistance: false,
      selectDurationUnit: "",
      memberData: [],
    };
    
  }

  validate = values => {
    const totalSelected =
      values.shelter.length + values.settlement.length + values.both.length;
    const totalOptions = this.state.memberData.length;

    if (totalSelected !== totalOptions) {
      return {
        unselectedOptions: "Please select all members",
      };
    }

    return {}; // No errors
  };

  componentDidMount = async () => {
    const { clientData, referenceNote, getClientData } = this.props;
    getClientData();
    var refNote = "";
    if (referenceNote) {
      let latestNote = null;
      for (const note of referenceNote) {
        if (
          note.form === formKey.SCREENING &&
          note.subForm === subFormKey.INQUIRY &&
          (!latestNote || new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const membersData = clientData?.generalInformation;

    const memberOptions = membersData?.members?.map(member => ({
      label: `${member.firstName} ${member.lastName}`,
      value: `${member.firstName} ${member.lastName}`,
    }));

    const leadMemberOption = [
      {
        label: `${membersData?.firstName} ${membersData?.lastName}`,
        value: `${membersData?.firstName} ${membersData?.lastName}`,
      },
    ];
    const options = [...leadMemberOption, ...memberOptions];
    this.setState({ memberData: options });
    const clientsData = clientData?.inquiryInformation;
    const durationParts = clientsData?.currentStayDuration?.split(" ");
    const shelterArray = clientsData?.shelter?.map(shelter => ({
      value: shelter,
      label: shelter,
    }));
    const settlementArray = clientsData?.settlement?.map(settlement => ({
      value: settlement,
      label: settlement,
    }));
    const bothArray = clientsData?.both?.map(both => ({
      value: both,
      label: both,
    }));
    this.setState({
      initialValues: {
        receivedSocialAssistance:
          clientsData?.receivedSocialAssistance.toString() || "",
        socialAssistanceProvince: clientsData?.socialAssistanceProvince || "",
        receivedSocialAssistanceInMonth:
          clientsData?.receivedSocialAssistanceInMonth.toString() || "",
        duration: durationParts?.[0] || "",
        durationUnit: durationParts?.[1] || "",
        // { value: durationParts?.[1], label: durationParts?.[1] } || "",
        currentStay: clientsData?.currentStay || "",
        currentStayOtherText: clientsData?.currentStayOtherText || "",
        otherStaySource: clientsData?.otherStaySource || "",
        otherStaySourceOtherText: clientsData?.otherStaySourceOtherText || "",
        consent: clientsData?.consent || "",
        consentOtherText: clientsData?.consentOtherText || "",
        shelter: shelterArray || [],
        settlement: settlementArray || [],
        both: bothArray || [],
        notes: clientsData?.notes,
        referenceNotes: refNote || "",
      },
      shelterOptions: shelterArray || [],
      settlementOptions: settlementArray || [],
      bothOptions: bothArray || [],

      // selectedSocialAssistance: clientData.receivedSocialAssistance,
      // selectDurationUnit: durationParts[1],
    });
  };

  handleSubmit = async (values, {setSubmitting, resetForm}) => {
    const {
      clientId,
      getClientData,
      generalInfo,
      immigrationInfo,
      inquiryInfo,
      toggleGeneralInfo,
      toggleImmigrationInfo,
      toggleInquiryInfo,
      setShowPageLoader,
    } = this.props;
    try {
      setShowPageLoader(true);
      const duration = `${values.duration} ${values.durationUnit}`;
      const requestBody = {
        type: {
          subForm_key: subFormKey.INQUIRY,
          formStatus: status.COMPLETED,
        },
        receivedSocialAssistance: values.receivedSocialAssistance,
        socialAssistanceProvince: values.socialAssistanceProvince,
        receivedSocialAssistanceInMonth: values.receivedSocialAssistanceInMonth,
        currentStayDuration: duration,
        currentStay: values.currentStay,
        currentStayOtherText: values.currentStayOtherText,
        otherStaySourceOtherText: values.otherStaySourceOtherText,
        otherStaySource: values.otherStaySource,
        consent: values.consent,
        consentOtherText: values.consentOtherText,
        shelter: values.shelter.map(item => item.value),
        settlement: values.settlement.map(item => item.value),
        both: values.both.map(item => item.value),
        notes: values.notes,
      };

      const response = await axiosPatch(
        `/add-client/screening/${clientId}/inquiry`,
        {
          requestBody: requestBody,
          referenceNotes: values?.referenceNotes,
        }
      );
      if (response?.status) {
        setShowPageLoader(false);
        toast.success(response?.message || "Client Updated Successfully");
        getClientData();
        if (generalInfo) {
          toggleGeneralInfo();
        }
        if (immigrationInfo) {
          toggleImmigrationInfo();
        }
        if (inquiryInfo) {
          toggleInquiryInfo();
        }
        setSubmitting(false);
        resetForm({values});
      } else {
        setShowPageLoader(false);
        toast.error("handleSubmit API error", response?.message);
        setSubmitting(false);
      }
    } catch (error) {
      setShowPageLoader(false);
      console.error("handleSubmit API error", error);
      setSubmitting(false);
    }
    // setSubmitting(false);
  };

  componentDidUpdate(prevProps) {
    // Check if the clientData props has changed
    if (prevProps.clientData !== this.props.clientData) {
      // Perform necessary actions when clientData props changes
      // For example, update the state with new data
      const membersData = this.props.clientData?.generalInformation;
      const memberOptions = membersData?.members?.map(member => ({
        label: `${member.firstName} ${member.lastName}`,
        value: `${member.firstName} ${member.lastName}`,
      }));
      const leadMemberOption = [
        {
          label: `${membersData?.firstName} ${membersData?.lastName}`,
          value: `${membersData?.firstName} ${membersData?.lastName}`,
        },
      ];
      const options = [...leadMemberOption, ...memberOptions];
      
      this.setState({ memberData: options });
      const clientsData = this.props.clientData?.inquiryInformation;

      const shelterArray = clientsData?.shelter
      ?.filter(shelter => options.some(member => member.value === shelter))
      .map(shelter => ({
        value: shelter,
        label: shelter,
      }));
    this.setState({ shelterOptions: shelterArray || [] });

    // Update settlement options
    const settlementArray = clientsData?.settlement
      ?.filter(settlement => options.some(member => member.value === settlement))
      .map(settlement => ({
        value: settlement,
        label: settlement,
      }));
    this.setState({ settlementOptions: settlementArray || [] });

    // Update both options
    const bothArray = clientsData?.both
      ?.filter(both => options.some(member => member.value === both))
      .map(both => ({
        value: both,
        label: both,
      }));
    this.setState({ bothOptions: bothArray || [] });
    }
  }
  render() {
    const {
      initialValues,
      shelterOptions,
      settlementOptions,
      bothOptions,
      memberData,
      formSaved
    } = this.state;
    const { clientData } = this.props;
    return (
      <React.Fragment>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validate={this.validate}
            onSubmit={
              this.handleSubmit
            }
          >
            {({ errors, touched, setFieldValue, values, dirty }) => (
              <Form>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="3">
                        <h4>Inquiry Information</h4>
                      </Col>
                      <Col md="9">
                        <div
                          className={
                            errors.unselectedOptions &&
                            touched.unselectedOptions
                              ? " is-invalid"
                              : ""
                          }
                        >
                          <Label className="form-label mb-3">
                            Specify needs for each member.
                            <span className="text-danger">*</span>
                          </Label>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label className="form-label fw-normal">
                                Shelter:
                              </Label>
                              <Select
                                id="shelter"
                                name="shelter"
                                value={shelterOptions}
                                options={memberData?.filter(
                                  option =>
                                    !values?.settlement?.includes(option) &&
                                    !values?.both?.includes(option)
                                )}
                                isMulti
                                onChange={selectedOption => {
                                  this.setState(
                                    { shelterOptions: selectedOption },
                                    () => {
                                      setFieldValue("shelter", selectedOption);
                                    }
                                  );
                                  // Remove selected option from settlement and both fields
                                  const updatedSettlement =
                                    values.settlement.filter(
                                      option => !selectedOption.includes(option)
                                    );
                                  const updatedBoth = values.both.filter(
                                    option => !selectedOption.includes(option)
                                  );

                                  setFieldValue(
                                    "settlement",
                                    updatedSettlement
                                  );
                                  setFieldValue("both", updatedBoth);
                                }}
                                placeholder="Select an option"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label className="form-label fw-normal">
                                Settlement:
                              </Label>
                              <Select
                                id="settlement"
                                name="settlement"
                                value={settlementOptions}
                                options={memberData?.filter(
                                  option =>
                                    !values?.shelter?.includes(option) &&
                                    !values?.both?.includes(option)
                                )}
                                isMulti
                                onChange={selectedOption => {
                                  this.setState(
                                    { settlementOptions: selectedOption },
                                    () => {
                                      setFieldValue(
                                        "settlement",
                                        selectedOption
                                      );
                                    }
                                  );
                                  // Remove selected option from shelter and both fields
                                  const updatedShelter =
                                    values?.shelter?.filter(
                                      option =>
                                        !selectedOption?.includes(option)
                                    );
                                  const updatedBoth = values?.both?.filter(
                                    option => !selectedOption?.includes(option)
                                  );

                                  setFieldValue("shelter", updatedShelter);
                                  setFieldValue("both", updatedBoth);
                                }}
                                placeholder="Select an option"
                              />
                            </div>
                          </Row>
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label className="form-label fw-normal">
                                Both:
                              </Label>
                              <Select
                                id="both"
                                name="both"
                                value={bothOptions}
                                options={memberData?.filter(
                                  option =>
                                    !values?.shelter?.includes(option) &&
                                    !values?.settlement?.includes(option)
                                )}
                                isMulti
                                onChange={selectedOption => {
                                  this.setState(
                                    { bothOptions: selectedOption },
                                    () => {
                                      setFieldValue("both", selectedOption);
                                    }
                                  );
                                  // Remove selected option from shelter and settlement fields
                                  const updatedShelter = values.shelter.filter(
                                    option => !selectedOption.includes(option)
                                  );
                                  const updatedSettlement =
                                    values.settlement.filter(
                                      option => !selectedOption.includes(option)
                                    );

                                  setFieldValue("shelter", updatedShelter);
                                  setFieldValue(
                                    "settlement",
                                    updatedSettlement
                                  );
                                }}
                                placeholder="Select an option"
                              />
                            </div>
                          </Row>
                          {errors.unselectedOptions &&
                            touched.unselectedOptions && (
                              <ErrorMessage
                                name="unselectedOptions"
                                component="div"
                                className="text-danger"
                              />
                            )}
                        </div>
                        <Row>
                          <Label className="form-label">
                            Are you receiving social assistance?
                            <span className="text-danger">*</span>
                          </Label>
                          <Col md="3">
                            <fieldset
                              className={
                                errors.receivedSocialAssistance &&
                                touched.receivedSocialAssistance
                                  ? " is-invalid"
                                  : ""
                              }
                            >
                              <Row>
                                <Col>
                                  <Label
                                    check
                                    className="d-flex align-items-center fw-normal"
                                  >
                                    <Field
                                      type="radio"
                                      name="receivedSocialAssistance"
                                      value="true"
                                      onClick={e => {
                                        //   this.setState(
                                        //     {
                                        //       selectedSocialAssistance:
                                        //         e.target.value === "true",
                                        //     },
                                        //     () => {
                                        //       setFieldValue(
                                        //         "receivedSocialAssistance",
                                        //         selectedSocialAssistance
                                        //       );
                                        //     }
                                        //   );
                                        setFieldValue(
                                          "socialAssistanceProvince",
                                          ""
                                        );
                                        setFieldValue(
                                          "receivedSocialAssistanceInMonth",
                                          "false"
                                        );
                                      }}
                                      className="me-1"
                                    />
                                    Yes
                                  </Label>
                                </Col>
                                <Col>
                                  <Label
                                    check
                                    className="d-flex align-items-center fw-normal"
                                  >
                                    <Field
                                      type="radio"
                                      name="receivedSocialAssistance"
                                      value="false"
                                      onClick={e => {
                                        //   this.setState(
                                        //     {
                                        //       selectedSocialAssistance:
                                        //         e.target.value === "false",
                                        //     },
                                        //     () => {
                                        //       setFieldValue(
                                        //         "receivedSocialAssistance",
                                        //         selectedSocialAssistance
                                        //       );
                                        //     }
                                        //   );

                                        setFieldValue(
                                          "socialAssistanceProvince",
                                          ""
                                        );
                                        setFieldValue(
                                          "receivedSocialAssistanceInMonth",
                                          "false"
                                        );
                                      }}
                                      className="me-1"
                                    />
                                    No
                                  </Label>
                                </Col>
                              </Row>
                            </fieldset>
                            <ErrorMessage
                              name="receivedSocialAssistance"
                              component="div"
                              className="invaild-feedback text-danger"
                            />
                          </Col>
                        </Row>
                        <Row>
                          {values.receivedSocialAssistance === "true" && (
                            <div className="col-md-6 mt-3">
                              <Label className="form-label">
                                {" "}
                                Province
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                id="socialAssistanceProvince"
                                name="socialAssistanceProvince"
                                classNamePrefix="react-select"
                                options={Object.keys(provincesAndCities).map(
                                  socialAssistanceProvince => ({
                                    value: socialAssistanceProvince,
                                    label: socialAssistanceProvince,
                                  })
                                )}
                                value={
                                  values.socialAssistanceProvince
                                    ? {
                                        value: values.socialAssistanceProvince,
                                        label: values.socialAssistanceProvince,
                                      }
                                    : null
                                }
                                onChange={option => {
                                  setFieldValue(
                                    "socialAssistanceProvince",
                                    option.value
                                  );
                                  setFieldValue(
                                    "receivedSocialAssistanceInMonth",
                                    false
                                  );
                                }}
                                className={
                                  errors.socialAssistanceProvince &&
                                  touched.socialAssistanceProvince
                                    ? " is-invalid"
                                    : ""
                                }
                                placeholder="Select a province"
                              />
                              <ErrorMessage
                                name="socialAssistanceProvince"
                                component="div"
                                className="invaild-feedback text-danger"
                              />
                            </div>
                          )}
                        </Row>
                        <Row>
                          {values.socialAssistanceProvince === "Quebec" &&
                            values.receivedSocialAssistance === "true" && (
                              <div className="col-md-6 mt-3">
                                <h6>
                                  If coming from Quebec, did you receive social
                                  assistance in this calendar month?
                                  <span className="text-danger">*</span>
                                </h6>
                                <Row>
                                  <Col lg="2">
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="receivedSocialAssistanceInMonth"
                                        value="true"
                                        className="me-1"
                                      />
                                      Yes
                                    </Label>
                                  </Col>
                                  <Col lg="2">
                                    <Label
                                      check
                                      className="d-flex align-items-center fw-normal"
                                    >
                                      <Field
                                        type="radio"
                                        name="receivedSocialAssistanceInMonth"
                                        value="false"
                                        className="me-1"
                                      />
                                      No
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                            )}
                        </Row>
                        <Row>
                          <div className="col-md-6 mb-3 mt-3">
                            <SingleSelectWithOther
                              name="currentStay"
                              label="Where are you staying now?"
                              options={stayInfo}
                              values={values}
                              value={values?.currentStay}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                              otherLabel="Enter other value"
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-6 mb-3">
                            <Label for="duration" className="form-label">
                              How long can you stay at your present place of
                              shelter?<span className="text-danger">*</span>
                            </Label>
                            <Row>
                              <div className="col-md-5">
                                <Field
                                  type="number"
                                  id="duration"
                                  name="duration"
                                  className={
                                    "form-control" +
                                    (errors.duration && touched.duration
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Enter the number of duration"
                                />
                                <ErrorMessage
                                  name="duration"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="col-md-7">
                                <Select
                                  // value={values.durationUnit}
                                  value={durationUnit.find(
                                    option =>
                                      option.value === values.durationUnit
                                  )}
                                  name="durationUnit"
                                  id="durationUnit"
                                  options={durationUnit}
                                  className={
                                    errors.durationUnit && touched.durationUnit
                                      ? " is-invalid"
                                      : ""
                                  }
                                  onChange={selectedOption =>
                                    setFieldValue(
                                      "durationUnit",
                                      selectedOption.value
                                    )
                                  }
                                  placeholder="Select a duration unit"
                                />
                                <ErrorMessage
                                  name="durationUnit"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </Row>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-6 mb-3">
                            <SingleSelectWithOther
                              name="otherStaySource"
                              label="Do you have other sources where you can stay?"
                              options={otherSources}
                              values={values}
                              value={values?.otherStaySource}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                              otherLabel="Enter other source name"
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-6 mb-3">
                            <SingleSelectWithOther
                              name="consent"
                              label="May we have your consent to share your need for shelter with the City of Windsor and other housing providers?"
                              options={consent}
                              values={values}
                              value={values?.consent}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                              otherLabel="Enter other text"
                            />
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-6 mb-3">
                            <Label for="notes" className="form-label">
                              Counsellor Notes
                            </Label>
                            <Field
                              as="textarea"
                              style={{resize: "none"}}
                              id="notes"
                              name="notes"
                              className="form-control"
                              placeholder="Enter any extra notes here."
                            />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Row>
                        <Col md="3">
                          <h4 className="form-label">Reference Notes</h4>
                        </Col>
                        <Col md="9">
                          <Field
                            id="referenceNotes"
                            name="referenceNotes"
                            as="textarea"
                            style={{resize: "none"}}
                            className="form-control"
                            placeholder="Enter any notes or references"
                          />
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Row>
                  <Col>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                      <Button type="submit" color="primary">
                        {/* {clientData?.inquiryInformation?.type?.formStatus ===
                                  formStatus.COMPLETED
                                  ? "Edit & Save"
                                  : "Save & Next"} */}
                        {clientData?.inquiryInformation?.type?.formStatus ===
                        formStatus.COMPLETED
                          ? dirty
                            ? "Save"
                            : "Edit"
                          : "Save & Next"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  generalInfo: state.Clients.generalInfo,
  immigrationInfo: state.Clients.immigrationInfo,
  inquiryInfo: state.Clients.inquiryInfo,
});

const mapDispatchToProps = dispatch => ({
  toggleGeneralInfo: () => dispatch(toggleGeneralInfo()),
  toggleImmigrationInfo: () => dispatch(toggleImmigrationInfo()),
  toggleInquiryInfo: () => dispatch(toggleInquiryInfo()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InquiryInfo);
