import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";
import { formKey, settlementSubFormKey } from "constants/constants";
import { toast } from "react-toastify";
import { axiosPatch } from "helpers/api_helpers";
import CustomDatePicker from "components/Common/DateField";

const validationSchema = Yup.object().shape({
  referrenceNote: Yup.string().required("Required"),
  paidBy: Yup.string().required("Required"),
  appointmentDate: Yup.date().required("Required"),
});

class Xray extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        referrenceNote: "",
        paidBy: "",
        appointmentDate: "",
      },
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;
    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.SETTLEMENT &&
          note.subForm === settlementSubFormKey.XRAY &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const appointmentDate = clientData?.settlement?.xray?.appointmentDate;
    this.setState({
      initialValues: {
        paidBy: clientData?.settlement?.xray?.paidBy || "",
        appointmentDate: appointmentDate
          ? new Date(appointmentDate).toISOString().split("T")[0]
          : "" || "",
        referrenceNote: refNote || "",
      },
    });
  };

  handleAddSettlementXrayInfo = async data => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/settlement/xray/${clientData?.id}`,
        data
      );
      if (response?.status) {
        toast.success(
          response?.message || "IRCC information stored successfully!"
        );
        handleGetClient();
        toggle("6");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error handleAddSettlementXrayInfo: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      xRayInfo: {
        paidBy: values?.paidBy,
        appointmentDate: values?.appointmentDate,
      },
      referenceNotes: values?.referrenceNote,
    };
    this.handleAddSettlementXrayInfo(data);
  };

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              this.handleSubmit(values);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Lawyer</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-9 mb-3 me-3 d-flex">
                            <label htmlFor="paidBy" className="form-label me-3">
                              Paid By
                              <span className="text-danger"> *</span>
                            </label>
                            <div
                              role="group"
                              aria-labelledby="paidBy"
                              className="form-check"
                            >
                              <label className="me-5">
                                <Field
                                  id="paidBy"
                                  name="paidBy"
                                  type="radio"
                                  value="mathew_house"
                                  className="form-check-input"
                                />
                                Mathew Homes
                              </label>
                              <label>
                                <Field
                                  id="paidBy"
                                  name="paidBy"
                                  type="radio"
                                  value="ircc"
                                  className="form-check-input"
                                />
                                IRCC
                              </label>
                            </div>
                            <ErrorMessage
                              name="paidBy"
                              component="div"
                              className={
                                values?.paidBy
                                  ? "invalid-feedback"
                                  : "text-danger ms-4"
                              }
                            />
                          </div>
                          <div className="col-md-4 me-3">
                            <Label for="appointmentDate" className="form-label">
                              Appointment Date
                              <span className="text-danger">*</span>
                            </Label>
                            {/* <Field
                              id="appointmentDate"
                              name="appointmentDate"
                              type="date"
                              className={
                                "form-control" +
                                (errors?.appointmentDate &&
                                touched?.appointmentDate
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder=""
                            /> */}
                            <CustomDatePicker
                              className={
                                "form-control" +
                                (errors?.appointmentDate &&
                                touched?.appointmentDate
                                  ? " is-invalid"
                                  : "")
                              }
                              selected={
                                values?.appointmentDate
                                  ? values.appointmentDate
                                  : null
                              }
                              onChange={date => {
                                if (date) {
                                  setFieldValue("appointmentDate", date);
                                } else {
                                  setFieldValue("appointmentDate", null);
                                }
                              }}
                              placeholderText="YYYY-MM-DD"
                            />
                            <ErrorMessage
                              name="appointmentDate"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label for="referrenceNote" className="form-label">
                              Referrence Note
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="referrenceNote"
                              name="referrenceNote"
                              as="textarea"
                              className={
                                "form-control" +
                                (errors?.referrenceNote &&
                                touched?.referrenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              rows={5}
                              placeholder=""
                            />

                            <ErrorMessage
                              name="referrenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Xray));
