import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { setShowPageLoader } from "store/actions";
import { connect } from "react-redux";

import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import LegalAid from "./LegalAid";
import Lawyer from "./Lawyer";
import Ircc from "./Ircc";
import Medical from "./Medical";
import Xray from "./Xray";
import WorkPermit from "./WorkPermit";
import Hearing from "./Hearing";
import { axiosGet } from "helpers/api_helpers";
import { capitalizeFirstLetter } from "constants/constants";

class Shettlement extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      open: "1",
      clientData: {},
    };
  }

  componentDidMount = () => {
    this.handleGetClientData();
  };

  toggle = id => {
    if (this.state.open === id) {
      this.setState({ open: "" });
    } else {
      this.setState({ open: id });
    }
  };

  handleGetClientData = async () => {
    const { match, showPageLoader } = this.props;
    const clientId = match?.params?.clientId;

    try {
      showPageLoader(true);
      if (clientId) {
        const response = await axiosGet(`/add-client/screening/${clientId}`);
        if (response?.status) {
          const getClientData = response?.data;
          this.setState({ clientData: getClientData }, () => {});
        }
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      console.error("handleGetScreeningData API error", error);
    }
  };

  render() {
    //meta title
    document.title = "Form | Settlement";

    const { clientData } = this.state;
    const clientName = `${capitalizeFirstLetter(
      clientData?.screeningForm?.generalInformation?.firstName
    )} ${capitalizeFirstLetter(
      clientData?.screeningForm?.generalInformation?.middleName
    )} ${capitalizeFirstLetter(
      clientData?.screeningForm?.generalInformation?.lastName
    )}`;
    const clientId = this.props.match?.params?.clientId;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Form" breadcrumbItem="Settlement" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between mb-3">
                      <h5>{clientName}</h5>
                      <Link to="/department/settlement">
                        <button className="btn btn-primary">Back</button>
                      </Link>
                    </div>
                    {clientId && Object.values(clientData).length ? (
                      <div>
                        <Accordion
                          flush
                          open={[this.state.open]}
                          toggle={this.toggle}
                        >
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="1">
                              Legal Aid
                            </AccordionHeader>
                            <AccordionBody accordionId="1">
                              <LegalAid
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="2">
                              Lawyer
                            </AccordionHeader>
                            <AccordionBody accordionId="2">
                              <Lawyer
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="3">
                              IRCC Portal
                            </AccordionHeader>
                            <AccordionBody accordionId="3">
                              <Ircc
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="4">
                              Medical
                            </AccordionHeader>
                            <AccordionBody accordionId="4">
                              <Medical
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="5">XRay</AccordionHeader>
                            <AccordionBody accordionId="5">
                              <Xray
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="6">
                              Work Permit
                            </AccordionHeader>
                            <AccordionBody accordionId="6">
                              <WorkPermit
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                          <AccordionItem className="mb-3 border rounded-2 border-secondary border-opacity-50">
                            <AccordionHeader targetId="7">
                              Hearing
                            </AccordionHeader>
                            <AccordionBody accordionId="7">
                              <Hearing
                                clientData={clientData}
                                handleGetClient={this.handleGetClientData}
                                toggle={id => this.toggle(id)}
                              />
                            </AccordionBody>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

const mapStateToProps = ({ user }) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Shettlement));
