import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";
import {
  capitalizeFirstLetter,
  formKey,
  settlementSubFormKey,
} from "constants/constants";
import { toast } from "react-toastify";
import { axiosPatch } from "helpers/api_helpers";
import CustomDatePicker from "components/Common/DateField";

const validationSchema = Yup.object().shape({
  referrenceNote: Yup.string().required("Required"),
  procurementDate: Yup.date().required("Next hearing date is required"),
  expiryDate: Yup.date().required("Next hearing date is required"),
  statusOption: Yup.object().nullable().required(),
});

const statusOptions = [
  { label: "Started", value: "started" },
  { label: "In-progress", value: "in_progress" },
  { label: "Completed", value: "completed" },
];

class WorkPermit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        statusOption: "",
        procurementDate: "",
        expiryDate: "",
        referrenceNote: "",
      },
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;
    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.SETTLEMENT &&
          note.subForm === settlementSubFormKey.WORK_PERMIT &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const procurementDate = clientData?.settlement?.workPermit?.procurementDate;

    this.setState({
      initialValues: {
        statusOption: clientData?.settlement?.workPermit?.status
          ? {
              label: capitalizeFirstLetter(
                clientData?.settlement?.workPermit?.status
              ),
              value: clientData?.settlement?.workPermit?.status,
            }
          : "",
        procurementDate: procurementDate
          ? new Date(procurementDate).toISOString().split("T")[0]
          : "" || "",
        expiryDate: clientData?.settlement?.workPermit?.expiryDate
          ? new Date(clientData?.settlement?.workPermit?.expiryDate)
              .toISOString()
              .split("T")[0]
          : "" || "",
        referrenceNote: refNote || "",
      },
    });
  };

  handleAddSettlementWorkPermitInfo = async data => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/settlement/workPermit/${clientData?.id}`,
        data
      );
      if (response?.status) {
        toast.success(
          response?.message || "IRCC information stored successfully!"
        );
        handleGetClient();
        toggle("7");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error handleAddSettlementWorkPermitInfo: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      workPermitInfo: {
        status: values?.statusOption?.value,
        procurementDate: values?.procurementDate,
        expiryDate: values?.expiryDate,
      },
      referenceNotes: values?.referrenceNote,
    };
    this.handleAddSettlementWorkPermitInfo(data);
  };

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              this.handleSubmit(values);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Personal Information</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-4 mb-3">
                            <Label for="statusOption" className="form-label">
                              Status
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              id="statusOption"
                              name="statusOption"
                              value={values?.statusOption}
                              options={statusOptions}
                              onChange={selectedOption => {
                                setFieldValue("statusOption", selectedOption);
                              }}
                            />
                            <ErrorMessage
                              name="statusOption"
                              component="div"
                              className={
                                values?.statusOption
                                  ? "invalid-feedback"
                                  : "text-danger"
                              }
                            />
                          </div>
                          <div className="d-flex">
                            <div className="col-md-4 me-3">
                              <Label
                                for="procurementDate"
                                className="form-label"
                              >
                                Procurement Date
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                id="procurementDate"
                                name="procurementDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors.procurementDate &&
                                  touched.procurementDate
                                    ? " is-invalid"
                                    : "")
                                }
                              /> */}
                              <CustomDatePicker
                                className={
                                  "form-control" +
                                  (errors?.procurementDate &&
                                  touched?.procurementDate
                                    ? " is-invalid"
                                    : "")
                                }
                                max={new Date()}
                                selected={
                                  values?.procurementDate
                                    ? values.procurementDate
                                    : null
                                }
                                onChange={date => {
                                  if (date) {
                                    setFieldValue("procurementDate", date);
                                  } else {
                                    setFieldValue("procurementDate", null);
                                  }
                                }}
                                placeholderText="YYYY-MM-DD"
                              />
                              <ErrorMessage
                                name="procurementDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4">
                              <Label for="expiryDate" className="form-label">
                                Expiry Date
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                id="expiryDate"
                                name="expiryDate"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors.expiryDate && touched.expiryDate
                                    ? " is-invalid"
                                    : "")
                                }
                              /> */}
                              <CustomDatePicker
                                className={
                                  "form-control" +
                                  (errors?.expiryDate && touched?.expiryDate
                                    ? " is-invalid"
                                    : "")
                                }
                                selected={
                                  values?.expiryDate ? values.expiryDate : null
                                }
                                onChange={date => {
                                  if (date) {
                                    setFieldValue("expiryDate", date);
                                  } else {
                                    setFieldValue("expiryDate", null);
                                  }
                                }}
                                placeholderText="YYYY-MM-DD"
                              />
                              <ErrorMessage
                                name="expiryDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label for="referrenceNote" className="form-label">
                              Referrence Note
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="referrenceNote"
                              name="referrenceNote"
                              as="textarea"
                              className={
                                "form-control" +
                                (errors?.referrenceNote &&
                                touched?.referrenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              rows={5}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="referrenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WorkPermit));
