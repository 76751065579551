import React, { Component } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import SignatureCanvas from "react-signature-canvas";
import oldlogo from "../../../../assets/images/oldlogo.png";
import { Button, Col, Container, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
import { setShowPageLoader } from "store/actions";
import { PhoneInput } from "react-international-phone";
import { connect } from "react-redux";
import { axiosPatch } from "helpers/api_helpers";
import CustomDatePicker from "components/Common/DateField";

const validationSchema = Yup.object().shape({
  agreementDate: Yup.date().required("agreement date is required"),
  moveInDate: Yup.date().required("move in date is required"),
  memberDetails: Yup.array().of(
    Yup.object().shape({
      //   phoneNumber: Yup.string().matches(
      //     /^[0-9]{10}$/,
      //     "Phone number must be 10 digits"
      //   ),
      email: Yup.string().email("Invalid email").required("Email is required"),
      signature: Yup.string().required("Signature is required"),
      date: Yup.date().required("signature date is required"),
    })
  ),
  roomNumber: Yup.string().required("Room number is required"),
  monthlyFee: Yup.number()
    .positive("Must be positive")
    .required("Monthly fee is required"),
  staffSignature: Yup.string().required("Staff signature is required"),
  staffSignatureDate: Yup.date().required("Staff signature date required"),
});

class HousingAgreementForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        agreementDate: "",
        moveInDate: "",
        memberDetails: [],
        roomNumber: "",
        monthlyFee: "",
        staffSignature: "",
        staffSignatureDate: "",
      },
    };
    this.signatureRefs = this.state.initialValues.memberDetails.map(() =>
      React.createRef()
    );
    this.staffSignatureRef = React.createRef();
  }
   mergeMemberData = (existingMembers, filteredMembers) => {
    return filteredMembers.map(filtered => {
      const existing = existingMembers.find(
        member => member.memberId === filtered.memberId
      );
      return {
        memberId: filtered.memberId,
        memberName: `${filtered.firstName || ""} ${
          filtered.middleName || ""
        } ${filtered.lastName || ""}`.trim(),
        phoneNumber: existing?.phoneNumber || filtered?.phoneNumber || "",
        email: filtered?.email || "",
        signature: existing?.signature || "",
        date: existing?.date
          ? new Date(existing.date).toISOString().split("T")[0]
          : "",
      };
    });
  };
  componentDidMount = () => {
    const { clientData } = this.props;

    try {
      const leadClientData = {
        memberId: clientData.screeningForm.generalInformation.memberHouseholdId,
        firstName: clientData.screeningForm?.generalInformation?.firstName,
        lastName: clientData.screeningForm?.generalInformation?.lastName,
        middleName: clientData.screeningForm?.generalInformation?.middleName,
        dateOfBirth: clientData.screeningForm?.generalInformation?.dateOfBirth,
        phoneNumber: clientData.screeningForm?.generalInformation?.phoneNumber,
        email: clientData.screeningForm?.generalInformation?.email,
        relation: "Self",
      };
      const allMembersData = [
        leadClientData,
        ...clientData.intakeForm?.personalInformation?.members,
      ];
      const filteredMembers = allMembersData?.filter(member => {
        const birthDate = new Date(member.dateOfBirth);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
        return age >= 16;
      });
      
      // Set initial values with filtered members
      const existingAgreement = clientData?.shelter?.housingAgreement;
      this.setState({
        initialValues: {
          agreementDate: existingAgreement?.agreementDate
            ? new Date(existingAgreement?.agreementDate)
                .toISOString()
                .split("T")[0]
            : "" || "",
          moveInDate: existingAgreement?.moveInDate
            ? new Date(existingAgreement?.moveInDate).toISOString().split("T")[0]
            : "" || "",
            memberDetails: existingAgreement.memberDetails.length > 0
            ? this.mergeMemberData(existingAgreement.memberDetails, filteredMembers)
            : filteredMembers.map(member => ({
                memberId: member.memberId,
                memberName: `${member.firstName || ""} ${
                  member.middleName || ""
                } ${member.lastName || ""}`.trim(),
                phoneNumber: member.phoneNumber || "",
                email: member.email || "",
                signature: "",
                date: "",
              })),
          roomNumber: existingAgreement?.roomNumber || "",
          monthlyFee: existingAgreement?.monthlyFee || "",
          staffSignature: existingAgreement?.staffSignature || "",
          staffSignatureDate: existingAgreement?.staffSignatureDate
            ? new Date(existingAgreement?.staffSignatureDate)
                .toISOString()
                .split("T")[0]
            : "" || "",
        },
      });
    } catch (error) {
      toast.error(error);
    }
  };

  handleAddHousingAgreementData = async housingAgreementInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/shelter/housing-agreement/${clientData?.id}`,
        housingAgreementInfo
      );
      if (response?.status) {
        toast.success(
          response?.message || "Housing agreement stored successfully!"
        );
        handleGetClient();
        toggle("9"); // Adjust this number if needed to match your tab index
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      housingAgreementInfo: {
        agreementDate: values?.agreementDate,
        moveInDate: values?.moveInDate,
        memberDetails: values?.memberDetails?.map(detail => ({
          memberId: detail?.memberId,
          phoneNumber: detail?.phoneNumber,
          email: detail?.email,
          signature: detail?.signature,
          date: detail?.date,
        })),
        roomNumber: values?.roomNumber,
        monthlyFee: values?.monthlyFee,
        staffSignature: values?.staffSignature,
        staffSignatureDate: values?.staffSignatureDate,
      },
    };
    this.handleAddHousingAgreementData(data);
  };

  render() {
    const { initialValues } = this.state;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form className="housing-agreement-form consent-text">
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              <img
                src={oldlogo}
                alt="Matthew House logo"
                style={{ maxWidth: "200px" }}
              />
            </div>

            <h2
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Transitional Housing Agreement
            </h2>

            <p>
              This agreement is made on{" "}
              {/* <Field
                type="date"
                name="agreementDate"
                style={{ width: "120px", display: "inline-block" }}
                className="form-control"
              /> */}
              <CustomDatePicker
                className={
                  "form-control" +
                  (errors?.agreementDate && touched?.agreementDate
                    ? " is-invalid"
                    : "")
                }
                selected={values?.agreementDate ? values.agreementDate : null}
                onChange={date => {
                  if (date) {
                    setFieldValue("agreementDate", date);
                  } else {
                    setFieldValue("agreementDate", null);
                  }
                }}
                placeholderText="YYYY-MM-DD"
              />
              , and will start as of{" "}
              {/* <Field
                type="date"
                name="moveInDate"
                style={{ width: "120px", display: "inline-block" }}
                className="form-control"
              /> */}
              <CustomDatePicker
                className={
                  "form-control" +
                  (errors?.moveInDate && touched?.moveInDate
                    ? " is-invalid"
                    : "")
                }
                selected={values?.moveInDate ? values.moveInDate : null}
                onChange={date => {
                  if (date) {
                    setFieldValue("moveInDate", date);
                  } else {
                    setFieldValue("moveInDate", null);
                  }
                }}
                placeholderText="YYYY-MM-DD"
              />
            </p>
            <p>
              This agreement is between Matthew House Refugee Centre and the
              following individual(s), referred to herein as the Resident(s):
            </p>

            <FieldArray name="memberDetails">
              {({ remove, push }) => (
                <div>
                  {values.memberDetails.map((resident, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                      <Field
                        name={`memberDetails.${index}.memberName`}
                        style={{ width: "100%" }}
                        className={
                          "form-control" +
                          (errors.memberDetails?.[index]?.memberName &&
                          touched.memberDetails?.[index]?.memberName
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="Name"
                        disabled
                      />
                      <ErrorMessage
                        name={`memberDetails.${index}.memberName`}
                        component="div"
                        className="error"
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="w-50 mt-2 me-1">
                          <PhoneInput
                            containerStyle={{ marginTop: "10px" }}
                            inputStyle={{
                              width: "600px",
                              maxHeight: "100%",
                            }}
                            value={values.memberDetails?.[index]?.phoneNumber}
                            onChange={phoneNumber => {
                              setFieldValue(
                                `memberDetails.${index}.phoneNumber`,
                                phoneNumber
                              );
                            }}
                            className={
                              errors.memberDetails?.[index]?.phoneNumber &&
                              touched.memberDetails?.[index]?.phoneNumber
                                ? " is-invalid"
                                : ""
                            }
                            disableDialCodePrefill={true}
                          />
                          <ErrorMessage
                            name={`memberDetails.${index}.phoneNumber`}
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="w-50 mt-2">
                          <Field
                            name={`memberDetails.${index}.email`}
                            placeholder="Email"
                            className={
                              "form-control" +
                              (errors.memberDetails?.[index]?.email &&
                              touched.memberDetails?.[index]?.email
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name={`memberDetails.${index}.email`}
                            component="div"
                            className="error"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>

            <p>
              (list all individuals over the age of 16 who will be
              participating)
            </p>

            <p>
              We enter into this agreement for residency at Room{" "}
              <Field
                type="text"
                name="roomNumber"
                style={{ width: "50px", display: "inline-block" }}
                className="form-control"
              />{" "}
              – 3185 Forest Glade Drive, Windsor, ON N8R 1W7, provided that the
              resident(s) adhere to the House Rules as outlined and signed upon
              entering the Matthew House residential program. In adherence with
              the Rental Fairness Act (2017) (RTA), this agreement is exempt
              from the Residential Tenancies Act (2006), for the purposes of the
              resident(s) participation in Matthew House's Refugee Claimant
              Settlement Program. Should you wish to apply to the Landlord and
              Tenant Board for a determination of whether the RTA applies, it is
              your right to do so.
            </p>

            <p>
              The purpose of this Transitional Housing Agreement is to
              facilitate temporary accommodations for the individuals identified
              herein, while they participate in a settlement and orientation
              plan through Matthew House Refugee Centre, and while they search
              for long-term housing. Only the resident(s) named above may sleep
              at the premises without written consent of the landlord.
            </p>

            <p>
              The resident(s), agree to pay the sum of $
              <Field
                type="number"
                name="monthlyFee"
                style={{ width: "80px", display: "inline-block" }}
                className="form-control"
              />
              /month for their shelter includes the following amenities:
            </p>

            <ul style={{ columns: 2, paddingLeft: "20px" }}>
              <li>Access to common areas</li>
              <li>Electricity, Heat, Water</li>
              <li>All furniture</li>
              <li>Bed linens</li>
              <li>Fridge & Stove (shared)</li>
              <li>Window coverings</li>
              <li>Pots, pans, plates, cutlery (shared)</li>
              <li>Towels</li>
            </ul>

            <p>
              The resident(s) acknowledges they may only reside in the room for
              90 days unless given written consent for extension. Further that
              in no circumstances will they be allowed to stay longer than one
              year. Failure to seek long-term housing, participate in their
              settlement plan through Matthew House, or breach of rules may
              result in immediate eviction.
            </p>

            <p>
              In the event the resident(s) is discharged with funds paid in
              advance, any unused portion will be refunded on a per diem basis.
              Matthew House is not responsible for any personal items with the
              resident(s) bring into their room. Personal items are always the
              sole responsibility and risk of the resident(s). Should the
              resident(s) be discharged, transportation to a local emergency
              shelter will be provided; and, the resident(s) will be permitted
              to continue to make use of Matthew House's non-residential
              settlement services. If the resident(s) wishes to seek
              readmission, they may contact Matthew House staff to determine
              eligibility and availability. Any resident(s) not eligible for
              readmission will be provided with the reasons why, and
              alternatives they can pursue.
            </p>

            <p>
              Any disagreements or disputes may be submitted in writing to the
              Executive Director of Matthew House. A response will be provided
              within five business days. If the response is not deemed
              satisfactory, the resident(s) may submit their complaint, in
              writing to the Chair of the Board of Directors of Matthew House
              Refugee Centre. A response will be provided by the Board within
              five business days.
            </p>

            <p>
              I hereby agree to the terms above and to the rule as provided.
              Further, I acknowledge that failure to follow the terms of this
              agreement of the attached rules may result in immediate eviction.
            </p>

            <p>
              I acknowledge that this agreement supersedes any previous
              agreement, but that my original move in date remains as my initial
              occupancy date.
            </p>

            <FieldArray name="memberDetails">
              {({ remove, push }) => (
                <div>
                  {values.memberDetails.map((resident, index) => (
                    <div key={index} style={{ marginBottom: "20px" }}>
                      <div className="d-flex gap-3 align-items-center">
                        {/* Name and Signature */}
                        <div style={{ width: "48%" }}>
                          <Label className="form-label">
                            {resident.memberName} Signature{" "}
                            <span className="text-danger">*</span>
                          </Label>

                          {values.memberDetails[index].signature ? (
                            <img
                              src={values.memberDetails[index].signature}
                              alt={`${resident.memberName} signature`}
                              className="canvas"
                              style={{ width: "50%", height: "auto" }}
                              onError={event => {
                                event.target.style.display = "none";
                                console.error(
                                  `Failed to load signature image: ${values.memberDetails[index].signature}`
                                );
                              }}
                            />
                          ) : (
                            <SignatureCanvas
                              penColor="black"
                              canvasProps={{
                                className: "canvas",
                                width: 250,
                                height: 60,
                              }}
                              ref={ref => (this[`signatureRef_${index}`] = ref)}
                              onEnd={() => {
                                const signatureData =
                                  this[`signatureRef_${index}`].toDataURL();
                                setFieldValue(
                                  `memberDetails.${index}.signature`,
                                  signatureData
                                );
                              }}
                            />
                          )}
                          <ErrorMessage
                            name={`memberDetails.${index}.signature`}
                            component="div"
                            className={`invalid-feedback text-danger ${
                              errors?.memberDetails?.[index]?.signature
                                ? "d-block"
                                : ""
                            }`}
                          />
                        </div>

                        {/* Clear Button */}
                        <div style={{ width: "15%" }}>
                          <button
                            type="button"
                            className="btn btn-secondary p-1 font-sm"
                            onClick={() => {
                              setFieldValue(
                                `memberDetails.${index}.signature`,
                                ""
                              );
                              this[`signatureRef_${index}`]?.clear(); // Clear the signature canvas
                            }}
                          >
                            Clear
                          </button>
                        </div>

                        {/* Date Field */}
                        <div style={{ width: "30%" }}>
                          <Label className="form-label">
                            Date <span className="text-danger">*</span>
                          </Label>
                          {/* <Field
                            type="date"
                            name={`memberDetails.${index}.date`}
                            className="form-control"
                          /> */}
                          <CustomDatePicker
                            className={
                              "form-control" +
                              (errors?.memberDetails?.[index]?.date &&
                              touched?.memberDetails?.[index]?.date
                                ? " is-invalid"
                                : "")
                            }
                            selected={
                              values?.memberDetails?.[index]?.date
                                ? values.memberDetails?.[index]?.date
                                : null
                            }
                            onChange={date => {
                              if (date) {
                                setFieldValue(
                                  `memberDetails.${index}.date`,
                                  date
                                );
                              } else {
                                setFieldValue(
                                  `memberDetails.${index}.date`,
                                  null
                                );
                              }
                            }}
                            placeholderText="YYYY-MM-DD"
                          />
                          <ErrorMessage
                            name={`memberDetails.${index}.signature`}
                            component="div"
                            className={`invalid-feedback text-danger ${
                              errors?.memberDetails?.[index]?.date
                                ? "d-block"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>

            <p>
              The above noted individuals have been approved for Transitional
              Housing as identified.
            </p>

            <div className="d-flex gap-3 align-items-center">
              {/* Staff Signature */}
              <div style={{ width: "48%" }}>
                <Label className="form-label">
                  Matthew House Staff Signature{" "}
                  <span className="text-danger">*</span>
                </Label>

                {values.staffSignature ? (
                  <img
                    src={values.staffSignature}
                    alt="Staff Signature"
                    className="canvas"
                    style={{ width: "50%", height: "auto" }}
                    onError={event => {
                      event.target.style.display = "none";
                      console.error(
                        `Failed to load signature image: ${values.staffSignature}`
                      );
                    }}
                  />
                ) : (
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      className: "canvas",
                      width: 250,
                      height: 60,
                    }}
                    ref={ref => (this.staffSignatureRef = ref)}
                    onEnd={() => {
                      const signatureData = this.staffSignatureRef.toDataURL();
                      setFieldValue("staffSignature", signatureData);
                    }}
                  />
                )}
              </div>
              <ErrorMessage
                name="staffSignature"
                component="div"
                className={`invalid-feedback text-danger ${
                  errors.staffSignature ? "d-block" : ""
                }`}
              />
              {/* Clear Button */}
              <div style={{ width: "15%" }}>
                <Button
                  type="button"
                  className="btn btn-secondary p-1 font-sm"
                  onClick={() => {
                    setFieldValue("staffSignature", ""); // Clear the signature field
                    this.staffSignatureRef?.clear(); // Clear the signature canvas
                  }}
                >
                  Clear
                </Button>
              </div>

              {/* Date Field */}
              <div style={{ width: "30%" }}>
                <Label className="form-label">
                  Date <span className="text-danger">*</span>
                </Label>
                {/* <Field
                  type="date"
                  name="staffSignatureDate"
                  className="form-control"
                /> */}
                <CustomDatePicker
                  className={
                    "form-control" +
                    (errors?.staffSignatureDate && touched?.staffSignatureDate
                      ? " is-invalid"
                      : "")
                  }
                  selected={
                    values?.staffSignatureDate
                      ? values.staffSignatureDate
                      : null
                  }
                  onChange={date => {
                    if (date) {
                      setFieldValue("staffSignatureDate", date);
                    } else {
                      setFieldValue("staffSignatureDate", null);
                    }
                  }}
                  placeholderText="YYYY-MM-DD"
                />
                <ErrorMessage
                  name="staffSignatureDate"
                  component="div"
                  className={`invalid-feedback text-danger ${
                    errors.staffSignatureDate ? "d-block" : ""
                  }`}
                />
              </div>
            </div>

            <p style={{ marginTop: "20px" }}>
              Questions regarding this agreement should be directed to:
            </p>
            <p>
              Mike Morency,
              <br />
              Executive Director Matthew House Refugee Centre
              <br />
              3185 Forest Glade Drive, Windsor, ON N8R 1W7
              <br />
              Phone: (519) 945-7627 ext. 4
            </p>

            <div className="d-flex justify-content-end mb-3">
              <Button type="submit" color="primary">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HousingAgreementForm);
