import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import AddMembers from "./AddMembers";
import {
  gender,
  orgName,
  languages,
  formKey,
  subFormKey,
  status,
  formStatus,
} from "../../constants/constants";
import {
  setShowPageLoader,
  toggleGeneralInfo,
  toggleImmigrationInfo,
  toggleInquiryInfo,
} from "store/actions";
import SingleSelectWithOther from "components/Common/SingleSelectWithOther";
import Select from "react-select";
import { axiosPost, axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { countryOptions } from "../../utils/common";
import CustomDatePicker from "components/Common/DateField";

// import { PhoneNumberUtil } from "google-libphonenumber";

const formatUCI = uci => {
  if (uci.length < 8 || uci.length > 10 || uci.length == 9) {
    return uci;
  }
  // Remove hyphens and commas using replace()
  const cleanedUci = uci.replace(/[-,]/g, "");

  // Format based on cleanedUci length (using a switch statement for clarity)
  switch (cleanedUci.length) {
    case 8:
      return `${cleanedUci.slice(0, 4)}-${cleanedUci.slice(4)}`;
    case 10:
      return `${cleanedUci.slice(0, 2)}-${cleanedUci.slice(
        2,
        6
      )}-${cleanedUci.slice(6)}`;
    default:
      return uci; // Unreachable but added for completeness
  }
};
const phoneRegExp =
  /^\+?\d{1,4}?[-.\s]?\(?(?:\d{2,5}|\d{4})\)?[-.\s]?\d\d\d?[-.\s]?\d\d\d?[-.\s]?\d\d\d$/;
const validationSchema = Yup.object().shape({
  uci: Yup.string().matches(
    /^\d{8}$|^\d{10}$/,
    "UCI must be either 8 or 10 digits"
  ),
  firstName: Yup.string().required("Please enter your first name"),
  lastName: Yup.string().required("Please enter your last name"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Invalid phone number format"),
  email: Yup.string()
    .email("Please enter a vaild email address")
    .required("Please enter your email address"),
  gender: Yup.string().required("Please select a gender"),
  dateOfBirth: Yup.date().required("Please choose your date of birth"),
  homeCountry: Yup.string().required("Please select a country"),
  selectedOptions: Yup.array().min(1, "Please select atleast one language"),
  otherText: Yup.string().when("selectedOptions", {
    is: selectedOptions => selectedOptions.includes("Other"),
    then: Yup.string().required("Please enter other language"),
    otherwise: Yup.string().notRequired(),
  }),
  refPhoneNumber: Yup.string(),
  refEmail: Yup.string().email("Please enter a vaild email address"),
  referralName: Yup.string().required("Please select a referral organization"),
  referralNameOtherText: Yup.string().when("referralName", {
    is: option => option === "Other",
    then: Yup.string().required("Please enter other organization name"),
    otherwise: Yup.string().notRequired(),
  }),
  memberForms: Yup.array().of(
    Yup.object().shape({
      memberUci: Yup.string().matches(
        /^\d{8}$|^\d{10}$/,
        "UCI must be either 8 or 10 digits"
      ),
      memberFirstName: Yup.string().required("Please enter member first name"),
      memberLastName: Yup.string().required("Please Enter member last name"),
      memberGender: Yup.string().required("Please select a gender"),
      memberDob: Yup.date().required("Please choose member's date of birth"),
      memberRelation: Yup.string().required(
        "Please choose relation with lead client"
      ), // these constraints take precedence
      // these constraints take precedence
    })
  ),
  // these constraints are shown if and only if inner constraints are satisfied
});

class GeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLanguages: [],
      initialValues: {},
      formSaved: false,
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = async () => {
    const { clientData } = this.props;
    const languagesObjectsArray = clientData?.languages?.map(language => ({
      value: language,
      label: language,
    }));
    this.setState({
      selectedLanguages: languagesObjectsArray || [],
    });
  };

  handleSubmit = async values => {
    const {
      getClientData,
      generalInfo,
      immigrationInfo,
      toggleGeneralInfo,
      toggleImmigrationInfo,
      clientId,
      setShowPageLoader,
    } = this.props;
    try {
      setShowPageLoader(true);
      const generalInformation = {
        type: {
          subForm_key: subFormKey.GENERAL,
          formStatus: status.COMPLETED,
        },
        uci: values?.uci,
        firstName: values?.firstName,
        lastName: values?.lastName,
        middleName: values?.middleName,
        gender: values?.gender,
        dateOfBirth: values?.dateOfBirth,
        homeCountry: values?.homeCountry,
        languages: values?.selectedOptions,
        languageOtherText: values?.otherText,
        members: values?.memberForms?.map(member => ({
          uci: member?.memberUci,
          firstName: member?.memberFirstName,
          lastName: member?.memberLastName,
          middleName: member?.memberMiddleName,
          gender: member?.memberGender,
          dateOfBirth: member?.memberDob,
          relation: member?.memberRelation,
        })),
        phoneNumber: values?.phoneNumber,
        email: values?.email,
        referralName: values?.referralName,
        referralNameOtherText: values?.referralNameOtherText,
        agentName: values?.agencyStaffName,
        refPhoneNumber: values?.refPhoneNumber,
        refEmail: values?.refEmail,
      };

      const requestBody = {
        type: {
          // is_completed: false,
          form_key: formKey.SCREENING,
        },
        generalInformation: generalInformation,
        referenceNotes: values?.referenceNotes,
      };

      if (!clientId) {
        const response = await axiosPost("/add-client/screening", requestBody);
        if (response?.status) {
          setShowPageLoader(false);
          toast.success(response?.message || "Client Created Successfully");
          const clientId = response?.data?.id;
          this.props.history.push(`/add-clients/${clientId}`);
          getClientData();
          if (generalInfo) {
            toggleGeneralInfo();
          }
          if (!immigrationInfo) {
            toggleImmigrationInfo();
          }
          this.setState({formSaved: true});
        } else {
          setShowPageLoader(false);
          toast.error(response?.message || "Oops! something went wrong");
        }
      } else {
        const response = await axiosPatch(
          `/add-client/screening/${clientId}/general`,
          {
            generalInformation: generalInformation,
            referenceNotes: values?.referenceNotes,
          }
        );
        if (response?.status) {
          setShowPageLoader(false);
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
          this.setState({formSaved: true});
        } else {
          setShowPageLoader(false);
          toast.error(response?.message || "Oops! something went wrong");
        }
      }
    } catch (error) {
      setShowPageLoader(false);
      console.error("handleSubmit API error", error);
    }
  };

  render() {
    const { selectedLanguages, formSaved } = this.state;
    const { clientData, referenceNote } = this.props;
    var refNote = "";
    if (referenceNote) {
      let latestNote = null;
      for (const note of referenceNote) {
        if (
          note.form === formKey.SCREENING &&
          note.subForm === subFormKey.GENERAL &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const initialValues = {
      uci: clientData?.uci || "",
      firstName: clientData?.firstName || "",
      lastName: clientData?.lastName || "",
      middleName: clientData?.middleName || "",
      phoneNumber: clientData?.phoneNumber || "",
      email: clientData?.email || "",
      refPhoneNumber: clientData?.refPhoneNumber || "",
      refEmail: clientData?.refEmail || "",
      gender: clientData?.gender || "",
      dateOfBirth: clientData.dateOfBirth
        ? new Date(clientData.dateOfBirth).toISOString().split("T")[0]
        : "",
      homeCountry: clientData?.homeCountry || "",
      otherValue: clientData?.otherValue || "",
      selectedOptions: clientData?.languages || [],
      otherText: clientData?.languageOtherText || "",
      referralName: clientData?.referralName || "",
      referralNameOtherText: clientData?.referralNameOtherText || "",
      agencyStaffName: clientData?.agentName || "",
      memberForms:
        clientData?.members?.map(member => ({
          memberUci: member?.uci || "",
          memberFirstName: member?.firstName || "",
          memberLastName: member?.lastName || "",
          memberMiddleName: member?.middleName || "",
          memberGender: member?.gender || "",
          memberDob: member?.dateOfBirth
            ? new Date(member.dateOfBirth).toISOString().split("T")[0]
            : "",
          memberRelation: member?.relation || "",
        })) || [],
      referenceNotes: refNote || "",
    };
    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Personal Information</h4>
                        </Col>
                        <Col md="9">
                          <Row className="mb-3">
                            <Col md="6">
                              <Label for="uci" className="form-label">
                                UCI#
                              </Label>
                              <Field
                                name="uci"
                                type="text"
                                className={
                                  " form-control" +
                                  (errors?.uci && touched?.uci
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter UCI Id"
                                value={formatUCI(values?.uci)}
                                onChange={e => {
                                  const inputValue = e.target.value.replace(
                                    /[^\d]/g,
                                    ""
                                  ); // Remove non-digit characters
                                  setFieldValue(`uci`, inputValue);
                                }}
                              />
                              <ErrorMessage
                                name="uci"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                          </Row>
                          <Row md="auto">
                            <div className="col-md-4 mb-3">
                              <Label for="firstName" className="form-label">
                                First name<span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="firstName"
                                name="firstName"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors?.firstName && touched?.firstName
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter first name"
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className=" col-md-4 mb-3">
                              <Label for="lastName" className="form-label">
                                Last name<span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="lastName"
                                name="lastName"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors?.lastName && touched?.lastName
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter last name"
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className=" col-md-4 mb-3">
                              <Label for="middleName" className="form-label">
                                Middle name
                              </Label>
                              <Field
                                id="middleName"
                                name="middleName"
                                type="text"
                                className="form-control"
                                placeholder="Enter middle name"
                              />
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <Col md="6">
                              <Label className="form-label">
                                Gender<span className="text-danger">*</span>
                              </Label>
                              <Select
                                id="gender"
                                name="gender"
                                options={gender}
                                value={gender.find(
                                  option => option?.value === values?.gender
                                )}
                                onChange={selectedOption => {
                                  setFieldValue(
                                    "gender",
                                    selectedOption?.value
                                  );
                                }}
                                className={
                                  errors?.gender && touched?.gender
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="Select gender"
                              />
                              <ErrorMessage
                                name="gender"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                            <Col md="6">
                              <Label for="dateOfBirth" className="form-label">
                                Date of birth
                                <span className="text-danger">*</span>
                              </Label>
                              <CustomDatePicker
                                className={
                                  "form-control" +
                                  (errors?.dateOfBirth && touched?.dateOfBirth
                                    ? " is-invalid"
                                    : "")
                                }
                                selected={
                                  values.dateOfBirth ? values.dateOfBirth : null
                                }
                                onChange={date => {
                                  if (date) {
                                    setFieldValue("dateOfBirth", date);
                                  } else {
                                    setFieldValue("dateOfBirth", null);
                                  }
                                }}
                                max={new Date()}
                                placeholderText="YYYY-MM-DD"
                              />
                              <ErrorMessage
                                name="dateOfBirth"
                                component="div"
                                className={`invalid-feedback text-danger ${
                                  errors.dateOfBirth ? "d-block" : ""
                                }`}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="6" className="mb-3">
                              <Label className="form-label">
                                Languages spoken
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                id="selectedOptions"
                                name="selectedOptions"
                                value={selectedLanguages}
                                isMulti={true}
                                onChange={value => {
                                  this.setState(
                                    { selectedLanguages: value },
                                    () => {
                                      // Update Formik field value after state update
                                      setFieldValue(
                                        "selectedOptions",
                                        value?.map(option => option.value)
                                      );
                                    }
                                  );
                                }}
                                className={
                                  "form-control" + errors.selectedOptions &&
                                  touched.selectedOptions
                                    ? " is-invalid"
                                    : ""
                                }
                                options={languages}
                                placeholder="Select languages"
                              />
                              <ErrorMessage
                                name="selectedOptions"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                            <Col md="6">
                              <Label className="form-label">
                                Home country
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                id="homeCountry"
                                name="homeCountry"
                                options={countryOptions}
                                value={countryOptions?.find(
                                  option =>
                                    option?.value === values?.homeCountry
                                )}
                                onChange={selectedOption => {
                                  setFieldValue(
                                    "homeCountry",
                                    selectedOption.value
                                  );
                                }}
                                onBlur={() => {
                                  // This ensures touched is set when the select is blurred
                                  if (!touched?.homeCountry) {
                                    setFieldValue(
                                      "homeCountry",
                                      values?.homeCountry
                                    );
                                  }
                                }}
                                className={
                                  errors?.homeCountry && touched?.homeCountry
                                    ? "is-invalid"
                                    : ""
                                }
                                placeholder="Select home country"
                              />
                              <ErrorMessage
                                name="homeCountry"
                                component="div"
                                className="invalid-feedback"
                              />
                            </Col>
                            {selectedLanguages.some(
                              lang => lang.value === "Other"
                            ) && (
                              <Col md="6">
                                <Label for="otherText" className="form-label">
                                  Other text
                                  <span className="text-danger">*</span>
                                </Label>
                                <Field
                                  id="otherText"
                                  type="text"
                                  name="otherText"
                                  onChange={e => {
                                    setFieldValue("otherText", e.target.value);
                                  }}
                                  value={values?.otherText}
                                  className={
                                    "form-control" +
                                    (errors?.otherText && touched?.otherText
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Other language"
                                />
                                <ErrorMessage
                                  name="otherText"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Family Member Details</h4>
                        </Col>
                        <Col md="9">
                          <div>
                            <FieldArray
                              name="memberForms"
                              render={({ remove, push }) => (
                                <AddMembers
                                  values={values}
                                  handleRemove={index => remove(index)}
                                  handleAdd={() =>
                                    push({
                                      memberUci: "",
                                      memberFirstName: "",
                                      memberLastName: "",
                                      memberMiddleName: "",
                                      memberGender: "",
                                      memberDob: "",
                                      memberRelation: "",
                                    })
                                  }
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                />
                              )}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="contact-info">
                      <Row>
                        <Col md="3">
                          <h4>Contact Information</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label for="phoneNumber" className="form-label">
                                Phone number
                                <span className="text-danger">*</span>
                              </Label>
                              <PhoneInput
                                containerStyle={{ marginTop: "10px" }}
                                inputStyle={{
                                  width: "600px",
                                  maxHeight: "100%",
                                }}
                                value={values.phoneNumber}
                                onChange={phoneNumber => {
                                  setFieldValue("phoneNumber", phoneNumber);
                                }}
                                className={
                                  errors.phoneNumber ? " is-invalid" : ""
                                }
                                disableDialCodePrefill={true}
                              />
                              <ErrorMessage
                                name="phoneNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <Label for="email" className="form-label">
                                Email Id<span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="email"
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors?.email && touched?.email
                                    ? " is-invalid"
                                    : "")
                                }
                                autoComplete="off"
                                placeholder="Enter your email address"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="referral-info">
                      <Row>
                        <Col md="3">
                          <h4>Referral Information</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <SingleSelectWithOther
                                name="referralName"
                                label={"Organization name"}
                                options={orgName}
                                values={values}
                                value={values?.referralName}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                otherLabel="Other organization name"
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <Label
                                for="agencyStaffName"
                                className="form-label"
                              >
                                If agency (Staff name)
                              </Label>
                              <Field
                                id="agencyStaffName"
                                name="agencyStaffName"
                                type="text"
                                className="form-control"
                                placeholder="Enter staff name"
                              />
                            </div>
                          </Row>

                          <Row>
                            <div className="col-md-6 mb-3">
                              <Label
                                for="refPhoneNumber"
                                className="form-label"
                              >
                                Phone number
                              </Label>
                              <PhoneInput
                                containerStyle={{ marginTop: "10px" }}
                                inputStyle={{
                                  width: "600px",
                                  maxHeight: "100%",
                                }}
                                value={values.refPhoneNumber}
                                onChange={refPhoneNumber => {
                                  // Check if refPhoneNumber is not empty and then set the field value
                                  if (refPhoneNumber.trim() !== "") {
                                    setFieldValue(
                                      "refPhoneNumber",
                                      refPhoneNumber
                                    );
                                  } else {
                                    // Set field value to empty if refPhoneNumber is empty
                                    setFieldValue("refPhoneNumber", "");
                                  }
                                }}
                                className={
                                  errors.refPhoneNumber ? " is-invalid" : ""
                                }
                                disableDialCodePrefill={true}
                              />
                              <ErrorMessage
                                name="refPhoneNumber"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <Label for="refEmail" className="form-label">
                                Email Id
                              </Label>
                              <Field
                                id="refEmail"
                                name="refEmail"
                                type="email"
                                placeholder="Enter email address"
                                className={
                                  " form-control" +
                                  (errors?.refEmail && touched?.refEmail
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="refEmail"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Row>
                        <Col md="3">
                          <h4 className="form-label">Reference notes</h4>
                        </Col>
                        <Col md="9">
                          <Field
                            id="referenceNotes"
                            name="referenceNotes"
                            as="textarea"
                            style={{ resize: "none" }}
                            className="form-control"
                            placeholder="Enter any notes or references"
                          />
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Row>
                  <Col>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                      <Button type="submit" color="primary">
                        {clientData?.type?.formStatus === formStatus.COMPLETED || formSaved
                          ? dirty
                            ? "Save"
                            : "Edit"
                          : "Save & Next"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

// export default withRouter(GeneralInfo);
const mapStateToProps = state => ({
  generalInfo: state.Clients.generalInfo,
  immigrationInfo: state.Clients.immigrationInfo,
  inquiryInfo: state.Clients.inquiryInfo,
});

const mapDispatchToProps = dispatch => ({
  toggleGeneralInfo: () => dispatch(toggleGeneralInfo()),
  toggleImmigrationInfo: () => dispatch(toggleImmigrationInfo()),
  toggleInquiryInfo: () => dispatch(toggleInquiryInfo()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GeneralInfo));
