import React, { Component } from "react";
import { Formik, Field, FieldArray, Form, ErrorMessage } from "formik";
import SignatureCanvas from "react-signature-canvas";
import oldlogo from "../../../../assets/images/oldlogo.png";
import { toast } from "react-toastify";
import { setShowPageLoader } from "store/actions";
import { connect } from "react-redux";
import { axiosPatch } from "helpers/api_helpers";
import { Button, Label } from "reactstrap";
import * as Yup from "yup";
import CustomDatePicker from "components/Common/DateField";

const validationSchema = Yup.object().shape({
  memberDetails: Yup.array().of(
    Yup.object().shape({
      signature: Yup.string().required("Signature is required"),
      date: Yup.date().required("Signature date is required"),
    })
  ),
});
class TransitionalHousingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        memberDetails: [],
      },
    };

    // Create refs for each signature canvas dynamically
    this.signatureRefs = this.state.initialValues.memberDetails.map(() =>
      React.createRef()
    );
  }
  mergeMemberData = (existingMembers, filteredMembers) => {

    return filteredMembers.map(filtered => {
      const existing = existingMembers.find(
        member => member.memberId === filtered.memberId
      );
      return {
        memberId: filtered.memberId,
        memberName: `${filtered.firstName || ""} ${
          filtered.middleName || ""
        } ${filtered.lastName || ""}`.trim(),
        signature: existing?.signature || "",
        date: existing?.date
          ? new Date(existing.date).toISOString().split("T")[0]
          : "",
      };
    });
  };
  componentDidMount = () => {
    const { clientData } = this.props;

    try {
      const leadClientData = {
        memberId: clientData?.screeningForm?.generalInformation?.memberHouseholdId,
        firstName: clientData?.screeningForm?.generalInformation?.firstName,
        lastName: clientData?.screeningForm?.generalInformation?.lastName,
        middleName: clientData?.screeningForm?.generalInformation?.middleName,
        dateOfBirth: clientData?.screeningForm?.generalInformation?.dateOfBirth,
        relation: "Self",
      };
      const allMembersData = [
        leadClientData,
        ...clientData?.intakeForm?.personalInformation?.members,
      ];
      const filteredMembers = allMembersData?.filter(member => {
        const birthDate = new Date(member.dateOfBirth);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
        return age >= 16;
      });
      const existingAgreement = clientData?.shelter?.housingRules;
      const memberDetails = existingAgreement.memberDetails.length > 0
      ? this.mergeMemberData(existingAgreement.memberDetails, filteredMembers)
      : filteredMembers.map(member => ({
          memberId: member.memberId,
          memberName: `${member.firstName || ""} ${
            member.middleName || ""
          } ${member.lastName || ""}`.trim(),
          signature: "",
          date: "",
        }))
      this.setState({
        initialValues: { memberDetails },
      });
    } catch (error) {
      toast.error(error);
    }
  };

  handleAddHousingRulesData = async housingAgreementInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/shelter/housing-rules/${clientData?.id}`,
        housingAgreementInfo
      );
      if (response?.status) {
        toast.success(response?.message || "Housing rules saved successfully!");
        handleGetClient();
        toggle("10"); // Adjust this number if needed to match your tab index
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      housingRulesInfo: {
        memberDetails: values?.memberDetails?.map(detail => ({
          memberId: detail?.memberId,
          signature: detail?.signature,
          date: detail?.date,
        })),
      },
    };
    this.handleAddHousingRulesData(data);
  };

  render() {
    const { initialValues } = this.state;
    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          this.handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className="housing-agreement-form consent-text">
            <div style={{ textAlign: "center", marginBottom: "20px" }}>
              <img
                src={oldlogo}
                alt="Matthew House logo"
                style={{ maxWidth: "200px" }}
              />
            </div>
            <div className="">
              <h2
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                Transitional Housing Rules
              </h2>
              <p>
                Matthew House Refugee Welcome Centre seeks to support Refugee
                Claimants as they settle into our region and become
                self-sufficient and contributing members of our community. We
                are a Christian charity that is 100% donor funded. We receive no
                government funding for our shelter or settlement services. Our
                Settlement Program aims to assist you in developing skills and
                executing a plan to effectively settle and integrate into
                Windsor. Should you have any requests or concerns, we ask you to
                speak with our staff.
              </p>

              <p>
                <b>
                  All memberDetails in our transitional housing program are
                  expected to:
                </b>
              </p>
              <ul>
                <li>
                  Show respect and consideration to each person living or
                  working at the residence. Violence or abusive language is not
                  tolerated and will result in immediate eviction from the
                  house.
                </li>
                <li>
                  Keep Matthew House staff updated with changes to their
                  circumstances and participate in their settlement plan
                  including actively searching for long-term housing each.
                </li>
                <li>
                  Observe quiet times (between 10pm to 7am). Out of respect for
                  others, please keep noise to a minimum during these times. No
                  cooking or watching television or videos in common areas
                  during these times (please use headphones if watching or
                  speaking on the phone in your room).
                </li>
                <li>
                  Not watch television, videos, or movies in common areas that
                  have inappropriate or objectionable language, excessive
                  violence or nudity. Please choose family-appropriate shows to
                  watch. The TV must be turned off by 10pm on weeknights and at
                  midnight on weekends.
                </li>
                <li>
                  Cleanup after yourself right away. No dirty dishes or open
                  food containers are to be kept in bedrooms.
                </li>
                <li>
                  Participate in one shared chore per week, like cleaning
                  bathroom, cutting grass, or washing floors. Individuals who
                  are unable to pay rent may be asked to do additional chores or
                  to volunteer time with a church or organization.
                </li>
                <li>
                  Not use or have in your possession, non-prescription drugs or
                  alcohol on the premises. If you are a tobacco smoker, you must
                  smoke 9 m away from the house.
                </li>
                <li>
                  Not share toothbrushes, razors or anything else that transmits
                  blood or saliva from one person to another. In addition,
                  please be sure to store all personal care products in your own
                  room/apartment.
                </li>
                <li>
                  Please limit shower/bathroom time out of courtesy for others.
                </li>
                <li>
                  Visitors are welcome in common areas of the house until 10pm.
                  Under no circumstances are visitors allowed to enter a guest’s
                  room/apartment or stay overnight without staff permission.
                </li>
                <li>
                  Every bedroom/apartment is for the private use of those living
                  in it. No resident may enter another resident's bedroom. Staff
                  may enter any room periodically to complete inspections for
                  general repairs, maintenance, and safety.
                </li>
                <li>
                  On the day you move, you must leave your room clean and in
                  order. This includes sweeping the floor, emptying your
                  drawers, cupboards, and garbage pail. Please do not take any
                  item that was in the room when you arrived unless permission
                  has been given.
                </li>
                <li>
                  All keys must be returned to office staff on the day you move
                  out. Lost keys must be reported and will result in a $25
                  replacement fee.
                </li>
                <li>
                  If a serious emergency occurs (such as a fire), and no staff
                  person is on site, call 911 on the telephone.
                </li>
                <li>
                  Not leave children under the age of 12 without the direct
                  supervision of an adult over the age of 18. Not leave children
                  between the age of 12 and 16 without direct adult supervision
                  for more than three hours or after 10pm at night. Staff cannot
                  be left responsible for your children unless they are
                  participating in an organized program.
                </li>
                <li>
                  If you will be out of the house past 10pm or overnight you
                  must notify staff, providing contact information for how to
                  reach you while away.
                </li>
              </ul>

              <p>
                Also note that, Matthew House and our partners always cooperate
                with emergency personnel and may release your information to
                them upon request.
              </p>

              <p>
                I have read the Transitional Housing Rules and agree to abide by
                them. Further, I acknowledge that failing to follow these rules
                may result in immediate eviction.
              </p>
            </div>

            {/* Dynamic Signature Fields */}
            <FieldArray name="memberDetails">
              {({ remove, push }) => (
                <div>
                  {values.memberDetails.map((resident, index) => (
                    <div key={index} style={{ marginBottom: "20px" }}>
                      <div className="d-flex gap-3 align-items-center">
                        {/* Name and Signature */}
                        <div style={{ width: "48%" }}>
                          <Label className="form-label">
                            {resident.memberName} Signature{" "}
                            <span className="text-danger">*</span>
                          </Label>

                          {values.memberDetails[index].signature ? (
                            <img
                              src={values.memberDetails[index].signature}
                              alt={`${resident.memberName} signature`}
                              className="canvas"
                              style={{ width: "50%", height: "auto" }}
                              onError={event => {
                                event.target.style.display = "none";
                                console.error(
                                  `Failed to load signature image: ${values.memberDetails[index].signature}`
                                );
                              }}
                            />
                          ) : (
                            <SignatureCanvas
                              penColor="black"
                              canvasProps={{
                                className: "canvas",
                                width: 250,
                                height: 60,
                              }}
                              ref={ref => (this[`signatureRef_${index}`] = ref)}
                              onEnd={() => {
                                const signatureData =
                                  this[`signatureRef_${index}`].toDataURL();
                                setFieldValue(
                                  `memberDetails.${index}.signature`,
                                  signatureData
                                );
                              }}
                            />
                          )}
                          <ErrorMessage
                            name={`memberDetails.${index}.signature`}
                            component="div"
                            className={`invalid-feedback text-danger ${
                              errors?.memberDetails?.[index]?.signature
                                ? "d-block"
                                : ""
                            }`}
                          />
                        </div>

                        {/* Clear Button */}
                        <div style={{ width: "15%" }}>
                          <button
                            type="button"
                            className="btn btn-secondary p-1 font-sm"
                            onClick={() => {
                              setFieldValue(
                                `memberDetails.${index}.signature`,
                                ""
                              );
                              this[`signatureRef_${index}`]?.clear(); // Clear the signature canvas
                            }}
                          >
                            Clear
                          </button>
                        </div>

                        {/* Date Field */}
                        <div style={{ width: "30%" }}>
                          <Label className="form-label">
                            Date <span className="text-danger">*</span>
                          </Label>
                          {/* <Field
                            type="date"
                            name={`memberDetails.${index}.date`}
                            className="form-control"
                          /> */}
                          <CustomDatePicker
                            className={
                              "form-control" +
                              (errors?.memberDetails?.[index]?.date &&
                              touched?.memberDetails?.[index]?.date
                                ? " is-invalid"
                                : "")
                            }
                            selected={
                              values?.memberDetails?.[index]?.date
                                ? values.memberDetails?.[index]?.date
                                : null
                            }
                            onChange={date => {
                              if (date) {
                                setFieldValue(
                                  `memberDetails.${index}.date`,
                                  date
                                );
                              } else {
                                setFieldValue(
                                  `memberDetails.${index}.date`,
                                  null
                                );
                              }
                            }}
                            placeholderText="YYYY-MM-DD"
                          />
                          <ErrorMessage
                            name={`memberDetails.${index}.date`}
                            component="div"
                            className={`invalid-feedback text-danger ${
                              errors?.memberDetails?.[index]?.date
                                ? "d-block"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>

            {/* Form Submit Button */}
            <div className="d-flex justify-content-end mb-3">
              <Button type="submit" color="primary">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransitionalHousingForm);
