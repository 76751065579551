import React from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { axiosPatch } from "helpers/api_helpers";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  formKey,
  formStatus,
  status,
  subFormKeyIntake,
} from "constants/constants";
import SignatureCanvas from "react-signature-canvas";

import {
  setShowPageLoader,
  togglePersonalInfo,
  toggleIntakeEducationalBackground,
  toggleIntakeEmploymentHistory,
  toggleIntakeHealthInfo,
  toggleIntakeHousingSection,
  toggleIntakeImmigrationInfo,
} from "store/actions";
import CustomDatePicker from "components/Common/DateField";

class IntakeHousingSection extends React.Component {
  constructor(props) {
    super(props);
    this.signatureRef = React.createRef();
  }

  handleSubmit = async values => {
    const {
      clientId,
      togglePersonalInfo,
      toggleIntakeHealthInfo,
      toggleIntakeImmigrationInfo,
      toggleIntakeEducationalBackground,
      toggleIntakeEmploymentHistory,
      personalInfo,
      intakeImmigrationInfo,
      intakeEmploymentHistory,
      intakeHousingSection,
      intakeHealthInfo,
      intakeEducationalBackground,
      getClientData,
      setShowPageLoader,
    } = this.props;

    if (clientId) {
      try {
        setShowPageLoader(true);
        const requestBody = {
          type: {
            is_completed: true,
            form_key: formKey.INTAKE,
          },
          housingInformation: {
            type: {
              subForm_key: subFormKeyIntake.HOUSING,
              formStatus: status.COMPLETED,
            },
            isAvailableOnWebsite: values.isAvailableOnWebsite,
            newsletters: values.newsletters,
            signature: values.signature,
            date: values.date,
          },
        };
        const response = await axiosPatch(
          `/add-client/intake/${clientId}/housing`,
          {
            requestBody: requestBody,
            referenceNotes: values?.referenceNotes,
          }
        );
        if (response?.status) {
          setShowPageLoader(false);
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
          if (intakeHousingSection) {
            toggleIntakeHousingSection();
          }
          if (intakeEmploymentHistory) {
            toggleIntakeEmploymentHistory();
          }
          if (intakeEducationalBackground) {
            toggleIntakeEducationalBackground();
          }
          if (intakeHealthInfo) {
            toggleIntakeHealthInfo();
          }
          if (personalInfo) {
            togglePersonalInfo();
          }
          if (intakeImmigrationInfo) {
            toggleIntakeImmigrationInfo();
          }
        } else {
          setShowPageLoader(false);
          toast.error("handleSubmit API error", response?.message);
        }
      } catch (error) {
        toast.error("handleSubmit API error", error);
      }
    }
  };
  render() {
    const { intakeData, referenceNote } = this.props;
    var refNote = "";
    if (referenceNote) {
      let latestNote = null;
      for (const note of referenceNote) {
        if (
          note.form === formKey.INTAKE &&
          note.subForm === subFormKeyIntake.HOUSING &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }

    const initialValues = {
      isAvailableOnWebsite: intakeData?.isAvailableOnWebsite || "",
      newsletters: intakeData?.newsletters || "",
      signature: intakeData?.signature || "",
      date: intakeData?.date
        ? new Date(intakeData?.date).toISOString().split("T")[0]
        : "",
      referenceNotes: refNote || "",
    };

    const validationSchema = Yup.object().shape({
      isAvailableOnWebsite: Yup.string().required("Please select a value"),
      newsletters: Yup.string().required("Please select an option"),
      signature: Yup.string().required("Signature is required"),
      date: Yup.date().required("Please enter date"),
      referenceNotes: Yup.string("Please enter a string"),
    });
    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Housing Section Details</h4>
                        </Col>
                        <Col md="6">
                          <div className="d-flex gap-0">
                            <div>
                              <Row>
                                <Label className="form-label">
                                  On its websites
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col md="8">
                                  <fieldset
                                    className={
                                      errors.isAvailableOnWebsite &&
                                      touched.isAvailableOnWebsite
                                        ? " is-invalid"
                                        : ""
                                    }
                                  >
                                    <Row>
                                      <Col xs="5">
                                        <Label
                                          check
                                          className="d-flex align-items-center fw-normal"
                                        >
                                          <Field
                                            type="radio"
                                            name="isAvailableOnWebsite"
                                            value="yes"
                                            className="me-1"
                                          />
                                          Yes
                                        </Label>
                                      </Col>
                                      <Col xs="4">
                                        <Label
                                          check
                                          className="d-flex align-items-center fw-normal"
                                        >
                                          <Field
                                            type="radio"
                                            name="isAvailableOnWebsite"
                                            value="no"
                                            className="me-1"
                                          />
                                          No
                                        </Label>
                                      </Col>
                                    </Row>
                                  </fieldset>
                                  <ErrorMessage
                                    name="isAvailableOnWebsite"
                                    component="div"
                                    className="invalid-feedback text-danger"
                                  />
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <Row>
                                <Label className="form-label">
                                  In - Newsletter presentation
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col md="8">
                                  <fieldset
                                    className={
                                      errors.newsletters && touched.newsletters
                                        ? " is-invalid"
                                        : ""
                                    }
                                  >
                                    <Row>
                                      <Col xs="4">
                                        <Label
                                          check
                                          className="d-flex align-items-center fw-normal"
                                        >
                                          <Field
                                            type="radio"
                                            name="newsletters"
                                            value="yes"
                                            className="me-1"
                                          />
                                          Yes
                                        </Label>
                                      </Col>
                                      <Col xs="4">
                                        <Label
                                          check
                                          className="d-flex align-items-center fw-normal"
                                        >
                                          <Field
                                            type="radio"
                                            name="newsletters"
                                            value="no"
                                            className="me-1"
                                          />
                                          No
                                        </Label>
                                      </Col>
                                    </Row>
                                  </fieldset>
                                  <ErrorMessage
                                    name="newsletters"
                                    component="div"
                                    className="invalid-feedback text-danger"
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>

                          <div className="d-flex gap-5 align-items-start mt-3 margin-l">
                            <div>
                              <Label className="form-label">
                                Signature
                                <span className="text-danger">*</span>
                              </Label>
                              {intakeData?.signature && values?.signature ? (
                                <img
                                  src={values?.signature}
                                  alt="Signature"
                                  className="canvas"
                                  onError={event => {
                                    // Handle potential errors if the image fails to load
                                    event.target.style.display = "none"; // Hide the broken image
                                    console.error(
                                      "Failed to Signature image:",
                                      values?.signature
                                    );
                                  }}
                                />
                              ) : (
                                <Field name="signature">
                                  {({ field }) => (
                                    <>
                                      <SignatureCanvas
                                        ref={this?.signatureRef}
                                        canvasProps={{
                                          className: "canvas",
                                          width: 150,
                                          height: 55,
                                        }}
                                        onEnd={() => {
                                          const data =
                                            this?.signatureRef?.current?.toDataURL();
                                          if (data) {
                                            setFieldValue(`signature`, data);
                                          } else {
                                            setFieldValue(`signature`, "");
                                          }
                                        }}
                                        {...field}
                                      />
                                      <ErrorMessage
                                        name="signature"
                                        component="div"
                                        className={`invalid-feedback text-danger ${
                                          errors.signature ? "d-block" : ""
                                        }`}
                                      />
                                    </>
                                  )}
                                </Field>
                              )}
                              <div className="mt-1">
                                <button
                                  type="button"
                                  className="btn btn-secondary p-1 pl-2 font-sm"
                                  onClick={() => {
                                    // Clear the image and show the signature canvas
                                    setFieldValue(
                                      `signature`,
                                      "" // Set the field value to an empty string
                                    );
                                    this?.signatureRef?.current?.clear(); // Clear the signature canvas
                                  }}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                            <div className="margin-date">
                              <Row>
                                <Label className="form-label">
                                  Date
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col md="9">
                                  {/* <Field
                                    type="date"
                                    name="date"
                                    className={
                                      "form-control" +
                                      (errors.date && touched.date
                                        ? " is-invalid"
                                        : "")
                                    }
                                    value={new Date(
                                      values.date
                                    ).toLocaleDateString("en-CA", {
                                      timeZone: "America/Toronto",
                                    })}
                                  /> */}
                                  <CustomDatePicker
                                    className={
                                      "form-control" +
                                      (errors?.date && touched?.date
                                        ? " is-invalid"
                                        : "")
                                    }
                                    selected={values.date ? values.date : null}
                                    onChange={date => {
                                      if (date) {
                                        setFieldValue("date", date);
                                      } else {
                                        setFieldValue("date", null);
                                      }
                                    }}
                                    placeholderText="YYYY-MM-DD"
                                  />
                                  <ErrorMessage
                                    name="date"
                                    component="div"
                                    className="invalid-feedback text-danger"
                                  />
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Reference Notes</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Field
                                name="referenceNotes"
                                as="textarea"
                                className={
                                  "form-control" +
                                  (errors.referenceNotes &&
                                  touched.referenceNotes
                                    ? " is-invalid"
                                    : "")
                                }
                                style={{ resize: "none" }}
                                placeholder="Enter notes"
                              />
                              <ErrorMessage
                                name="referenceNotes"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button type="submit" color="primary">
                            {intakeData?.type?.formStatus ===
                            formStatus.COMPLETED
                              ? dirty
                                ? "Save"
                                : "Edit"
                              : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntakeHousingSection));
