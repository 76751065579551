import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import { setShowPageLoader } from "store/actions";
import { formKey, orientationSubFormKey } from "constants/constants";
import { axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import CustomDatePicker from "components/Common/DateField";

const validationSchema = Yup.object().shape({
  agency: Yup.string().required("Required"),
  date: Yup.date().required("Required"),
  purpose: Yup.string().required("Required"),
});

const initialValues = {};

class Referrals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        agency: "",
        date: "",
        purpose: "",
        referrenceNote: "",
      },
    };
  }

  componentDidMount = async () => {
    const { clientData } = this.props;
    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.ORIENTATION &&
          note.subForm === orientationSubFormKey.REFERRAL &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    this.setState({
      initialValues: {
        agency: clientData?.orientation?.referral?.agency || "",
        date: clientData?.orientation?.referral?.referredDate
          ? new Date(clientData?.orientation?.referral?.referredDate)
              .toISOString()
              .split("T")[0]
          : "",
        purpose: clientData?.orientation?.referral?.purpose || "",
        referrenceNote: refNote || "",
      },
    });
  };

  handleAddOrientationReferral = async referralInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/orientation/referral/${clientData?.id}`,
        referralInfo
      );
      if (response?.status) {
        toast.success(
          response?.message || "Legal aid information stored successfully!"
        );
        handleGetClient();
        toggle("3");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      referralInfo: {
        agency: values?.agency,
        referredDate: values?.date,
        purpose: values?.purpose,
      },
      referenceNotes: values?.referrenceNote,
    };
    this.handleAddOrientationReferral(data);
  };

  render() {
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              setFieldTouched,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Referral Details</h4>
                        </Col>
                        <Col md="9">
                          <div className="d-flex">
                            <div className="col-md-4 mb-3 me-3">
                              <Label for="agency" className="form-label">
                                Agency
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="agency"
                                name="agency"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors?.agency && touched?.agency
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter Agency name"
                              />
                              <ErrorMessage
                                name="agency"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="col-md-4 mb-3">
                              <Label for="date" className="form-label">
                                Date
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Field
                                id="date"
                                name="date"
                                type="date"
                                className={
                                  "form-control" +
                                  (errors?.date && touched?.date
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter date"
                              /> */}
                              <CustomDatePicker
                                className={
                                  "form-control" +
                                  (errors?.date && touched?.date
                                    ? " is-invalid"
                                    : "")
                                }
                                selected={values?.date ? values.date : null}
                                onChange={date => {
                                  if (date) {
                                    setFieldValue("date", date);
                                  } else {
                                    setFieldValue("date", null);
                                  }
                                }}
                                placeholderText="YYYY-MM-DD"
                              />
                              <ErrorMessage
                                name="date"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-md-4 mb-3 me-3">
                              <Label for="purpose" className="form-label">
                                Purpose
                                <span className="text-danger">*</span>
                              </Label>
                              <Field
                                id="purpose"
                                name="purpose"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors?.purpose && touched?.purpose
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Enter purpose"
                              />
                              <ErrorMessage
                                name="purpose"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="9">
                          <div className="mb-3">
                            <Label for="referrenceNote" className="form-label">
                              Referrence Note
                              <span className="text-danger">*</span>
                            </Label>
                            <Field
                              id="referrenceNote"
                              name="referrenceNote"
                              as="textarea"
                              style={{ resize: "none" }}
                              className={
                                "form-control" +
                                (errors?.referrenceNote &&
                                touched?.referrenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              cols={100}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="referrenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Referrals));
