import React from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage, FieldArray } from "formik";
import SingleSelectWithOther from "components/Common/SingleSelectWithOther";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";

import {
  formStatus,
  frequency,
  sleepActivityList,
} from "constants/constants";
import { axiosPatch } from "helpers/api_helpers";
import {
  setShowPageLoader,
} from "store/actions";
import Select from "react-select";

class IntakeHealthInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
    };
    this.memberRefs = [];
  }

  componentDidMount = async () => {
    const { clientData } = this.props;

    try {
      const leadClientData = {
        memberHouseholdId: clientData?.memberHouseholdId,
        uci: clientData.uci,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        middleName: clientData.middleName,
        gender: clientData.gender,
        dateOfBirth: clientData.dateOfBirth,
        relation: "Self",
      };
      // Create array with lead client data and all members' data
      const allMembersData = [leadClientData, ...clientData.members];
      this.setState({ allMembers: allMembersData });
    } catch (error) {
      toast.error(error);
    }
  };

  handleSubmit = async values => {
    const {
      clientId,
      handleGetClient,
      setShowPageLoader,
      toggle
    } = this.props;

    if (clientId) {
      try {
        setShowPageLoader(true);
        const 
          medicalInformation = {
            healthDetails: values.healthDetails.map(member => ({
              memberId: member.memberId,
              expectingBaby: member.expectingBaby,
              babyInfo: member.babyInfo,
              womanWellness: member.womanWellness,
              womanWellnessInformation: member.womanWellnessInformation,
              generalHealth: member.generalHealth,
              physicalHealth: member.physicalHealth,
              medCondition: member.medCondition,
              medConditionText: member.medConditionText,
              generalText: member.generalText,
              physicalText: member.physicalText,
              lastMedicalCheckUp: member.lastMedicalCheckUp,
              currentMedications: member.currentMedications,
              currentMedicationsList: member.currentMedicationsList,
            })),
            activities: values.activities.map(member => ({
              memberId: member.memberId,
              sleepActivity: member.sleepActivity,
              sleepQuality: member.sleepQuality,
              sleepIssues: member.sleepIssues,
              sleepIssuesText: member.sleepIssuesText,
            })),
        };
        const response = await axiosPatch(
          `/add-client/shelter/medical-information/${clientId}`,
          {
            medicalInformation: medicalInformation,
          }
        );
        if (response?.status) {
          toast.success(response?.message || "Client Updated Successfully");
          handleGetClient();
          toggle("1");
        } else {
          toast.error("handleSubmit API error", response?.message);
        }
        setShowPageLoader(false);
      } catch (error) {
        toast.error("handleSubmit API error", error);
      }
    }
  };

  mergeMemberData = (intakeDataMembers, shelterDataMembers, allMembers) => {

    const mergedMembers = allMembers.map(member => {
      const intakeMember = intakeDataMembers.find(
        intakeMember => intakeMember.memberId === member.memberHouseholdId
      );
      const shelterMember = shelterDataMembers.find(
        shelterMember => shelterMember.memberId === member.memberHouseholdId
      );
      
      // Use shelter data if available, otherwise use intake data
      const sourceMember = shelterMember || intakeMember;

      const modifiedArrayMember = sourceMember?.currentMedicationsList?.map(
        obj => {
          // Get all keys except the last one
          let keys = Object.keys(obj).slice(0, -1);
          // Construct a new object with all key-value pairs except the last one
          let newObj = {};
          keys.forEach(key => {
            newObj[key] = obj[key];
          });
          return newObj;
        }
      );

      return {
        memberId: member?.memberHouseholdId || "",
        memberName: `${member?.firstName} ${member?.middleName} ${member?.lastName}`,
        generalHealth: sourceMember?.generalHealth || "",
        generalText: sourceMember?.generalText || "",
        physicalHealth: sourceMember?.physicalHealth || "",
        physicalText: sourceMember?.physicalText || "",
        lastMedicalCheckUp: sourceMember?.lastMedicalCheckUp || "",
        medCondition: sourceMember?.medCondition || "",
        medConditionText: sourceMember?.medConditionText || "",
        expectingBaby: sourceMember?.expectingBaby || "",
        babyInfo: sourceMember?.babyInfo || "",
        womanWellness: sourceMember?.womanWellness || "",
        currentMedications: sourceMember?.currentMedications || "",
        womanWellnessInformation: sourceMember?.womanWellnessInformation || "",
        currentMedicationsList: modifiedArrayMember || [],
        sleepActivity: sourceMember?.sleepActivity || "",
              sleepQuality: sourceMember?.sleepQuality || "",
              sleepIssues: sourceMember?.sleepIssues || "",
              sleepIssuesText: sourceMember?.sleepIssuesText || "",
      };
    });

    return mergedMembers;
  };
  render() {
    const { allMembers } = this.state;
    const { intakeData, shelterData } = this.props;
    const initialValues = {
      healthDetails:
        (intakeData?.healthDetails?.length > 0 || shelterData?.healthDetails?.length > 0) &&
        this.mergeMemberData(intakeData?.healthDetails || [], shelterData?.healthDetails || [], allMembers) ||
        allMembers?.map(member => ({
          memberId: member.memberHouseholdId,
          memberName: `${member.firstName} ${member.middleName} ${member.lastName}`,
          generalHealth: "",
          generalText: "",
          physicalHealth: "",
          physicalText: "",
          lastMedicalCheckUp: "",
          medCondition: "",
          medConditionText: "",
          expectingBaby: "",
          babyInfo: "",
          womanWellness: "",
          currentMedications: "",
          womanWellnessInformation: "",
          currentMedicationsList: [],
        })),
      activities:
        (intakeData?.activities?.length > 0 || shelterData?.activities?.length > 0) &&
        this.mergeMemberData(intakeData?.activities || [], shelterData?.activities || [], allMembers) ||
        allMembers?.map(member => ({
          memberId: member.memberHouseholdId,
          memberName: `${member.firstName} ${member.middleName} ${member.lastName}`,
          sleepActivity: "",
          sleepQuality: "",
          sleepIssues: "",
          sleepIssuesText: "",
        })),
    };
    const validationSchema = Yup.object().shape({
      referenceNotes: Yup.string("Notes should be in form of string."),
      healthDetails: Yup.array().of(
        Yup.object().shape({
          generalHealth: Yup.string().required("General health is required"),
          generalText: Yup.string().when("generalHealth", {
            is: "yes",
            then: Yup.string().required(
              "General health additional information is required"
            ),
          }),
          physicalHealth: Yup.string().required("Physical health is required"),
          physicalText: Yup.string().when("physicalHealth", {
            is: "yes",
            then: Yup.string().required("Additional information is required"),
          }),
          lastMedicalCheckUp: Yup.string().required(
            "Last medical check-up is required"
          ),
          medCondition: Yup.string().required("Medical condition is required"),
          medConditionText: Yup.string().when("medCondition", {
            is: "yes",
            then: Yup.string().required("Additional info is required"),
          }),
          expectingBaby: Yup.string().required(
            "Expecting baby status is required"
          ),
          babyInfo: Yup.string().when("expectingBaby", {
            is: "yes",
            then: Yup.string().required("Baby information is required"),
          }),
          womanWellness: Yup.string().required("Woman wellness is required"),
          currentMedications: Yup.string().required(
            "Please select any current medication option"
          ),
          womanWellnessInformation: Yup.string().when("womanWellness", {
            is: "yes",
            then: Yup.string().required(
              "Woman wellness information is required"
            ),
          }),
          currentMedicationsList: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required("Medication name is required"),
              dosage: Yup.string().required("Dosage is required"),
              frequency: Yup.string().required("Frequency is required"),
              frequencyOtherText: Yup.string().when("frequency", {
                is: "Other",
                then: Yup.string().required("Please enter the other value"),
                otherwise: Yup.string().notRequired(),
              }),
            })
          ),
        })
      ),
      activities: Yup.array().of(
        Yup.object().shape({
          sleepActivity: Yup.string().required("Sleep activity is required"),
          sleepQuality: Yup.string().required("Sleep quality is required"),
          sleepIssues: Yup.string().required("Sleep issues is required"),
          sleepIssuesText: Yup.string().when("sleepIssues", {
            is: "yes",
            then: Yup.string().required("Sleep issues text is required"),
            otherwise: Yup.string().notRequired(),
          }),
        })
      ),
    });
    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <hr></hr>
                    <div className="health-details-info">
                      <Row>
                        <Col md="3">
                          <h4>Health Details</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-6 mb-3">
                            {values.healthDetails.map((member, index) => (
                              <div
                                key={`${index}-${member.memberId}`}
                                className="mb-4"
                              >
                                <h5> {member?.memberName}</h5>

                                <Row className="mt-1 width-radio">
                                  <h6 className="mt-2">
                                    To make sure we are caring to the best of
                                    our ability :
                                  </h6>
                                  <Label className="form-label col-md-20 mt-3">
                                    {"\u2022"} Do you have any concerns about
                                    your general health that you would like to
                                    share with us?(If Yes, please specify)
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.generalHealth &&
                                        touched.healthDetails?.[index]
                                          ?.generalHealth
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col className="d-flex align-items-center">
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].generalHealth`}
                                              value="yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].generalHealth`}
                                              value="no"
                                              className="me-1"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].generalText`,
                                                  ""
                                                );
                                              }}
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].generalHealth`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails?.[index]
                                    ?.generalHealth === "yes" && (
                                    <div className="col-md-10 mb-3 mt-2">
                                      <Field
                                        name={`healthDetails[${index}].generalText`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.healthDetails?.[index]
                                            ?.generalText &&
                                          touched.healthDetails?.[index]
                                            ?.generalText
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`healthDetails[${index}].generalText`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}
                                </Row>
                                <Row className="mt-2 mb-3 width-radio">
                                  <Label className="form-label">
                                    {"\u2022"} Do you have any concerns about
                                    your physical health that you would like to
                                    share with us?(If Yes, please specify)
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.physicalHealth &&
                                        touched.healthDetails?.[index]
                                          ?.physicalHealth
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].physicalHealth`}
                                              value="yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].physicalHealth`}
                                              value="no"
                                              className="me-1"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].physicalText`,
                                                  ""
                                                );
                                              }}
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].physicalHealth`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails?.[index]
                                    ?.physicalHealth === "yes" && (
                                    <div className="col-md-10 mb-3">
                                      <Field
                                        name={`healthDetails[${index}].physicalText`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.healthDetails?.[index]
                                            ?.physicalText &&
                                          touched.healthDetails?.[index]
                                            ?.physicalText
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`healthDetails[${index}].physicalText`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}
                                </Row>
                                <Row>
                                  <Label className="form-label">
                                    {"\u2022"} Last medical check-up
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="20">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.lastMedicalCheckUp &&
                                        touched.healthDetails?.[index]
                                          ?.lastMedicalCheckUp
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <div className="d-flex gap-4">
                                        <div>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].lastMedicalCheckUp`}
                                              value="None"
                                              className="me-1"
                                            />
                                            None
                                          </Label>
                                        </div>
                                        <div>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal "
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].lastMedicalCheckUp`}
                                              value="WithinLastYear"
                                              className="me-1"
                                            />
                                            Within last year
                                          </Label>
                                        </div>
                                        <div>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].lastMedicalCheckUp`}
                                              value="OneToTwoYearsAgo"
                                              className="me-1"
                                            />
                                            1-2 years ago
                                          </Label>
                                        </div>
                                        <div>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].lastMedicalCheckUp`}
                                              value="ThreeToFourYearsAgo"
                                              className="me-1"
                                            />
                                            3-4 years ago
                                          </Label>
                                        </div>
                                        <div>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].lastMedicalCheckUp`}
                                              value="MoreThanFiveYearsAgo"
                                              className="me-1"
                                            />
                                            {">"}5 years ago
                                          </Label>
                                        </div>
                                      </div>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails.${index}.lastMedicalCheckUp`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row className="mt-3  width-radio">
                                  <Label className="form-label">
                                    {"\u2022"} Current medications
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.currentMedications &&
                                        touched.healthDetails?.[index]
                                          ?.currentMedications
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].currentMedications`}
                                              value="None"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].currentMedicationsList`,
                                                  []
                                                );
                                              }}
                                              className="me-1"
                                            />
                                            None
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].currentMedications`}
                                              value="Add"
                                              className="me-1"
                                            />
                                            Add
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].currentMedications`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails[index]
                                    .currentMedications === "Add" && (
                                    <FieldArray
                                      name={`healthDetails[${index}].currentMedicationsList`}
                                    >
                                      {({ push, remove }) => (
                                        <>
                                          {member.currentMedicationsList.map(
                                            (medication, medIndex) => (
                                              <div
                                                key={medIndex}
                                                className="mt-3"
                                              >
                                                <Label className="form-label">
                                                  Medication #{medIndex + 1}
                                                </Label>
                                                <div className="d-flex gap-3">
                                                  <div className="col-md-7">
                                                    <Label className="fw-normal">
                                                      Name
                                                    </Label>
                                                    <Field
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].name`}
                                                      type="text"
                                                      className={
                                                        " form-control" +
                                                        (errors.healthDetails?.[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList?.[
                                                          medIndex
                                                        ]?.name &&
                                                        touched.healthDetails?.[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList?.[
                                                          medIndex
                                                        ]?.name
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                      placeholder="Enter medication name"
                                                    />
                                                    <ErrorMessage
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].name`}
                                                      component="div"
                                                      className="invalid-feedback text-danger"
                                                    />
                                                  </div>
                                                  <div className="col-md-7">
                                                    <Label className="fw-normal">
                                                      Dosage
                                                    </Label>
                                                    <Field
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].dosage`}
                                                      type="text"
                                                      className={
                                                        " form-control" +
                                                        (errors.healthDetails?.[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList?.[
                                                          medIndex
                                                        ]?.dosage &&
                                                        touched.healthDetails?.[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList?.[
                                                          medIndex
                                                        ]?.dosage
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                      placeholder="Enter dosage"
                                                    />
                                                    <ErrorMessage
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].dosage`}
                                                      component="div"
                                                      className="invalid-feedback text-danger"
                                                    />
                                                  </div>
                                                  <div className="col-md-7">
                                                    <SingleSelectWithOther
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].frequency`}
                                                      label="Select frequency"
                                                      options={frequency}
                                                      values={values}
                                                      value={
                                                        values?.healthDetails[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList[
                                                          medIndex
                                                        ]?.frequency
                                                      }
                                                      setFieldValue={
                                                        setFieldValue
                                                      }
                                                      errors={errors}
                                                      touched={touched}
                                                      otherLabel="Other frequency"
                                                    />
                                                    <ErrorMessage
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].frequency`}
                                                      component="div"
                                                      className={`invalid-feedback text-danger ${
                                                        errors.healthDetails?.[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList?.[
                                                          medIndex
                                                        ]?.frequency
                                                          ? "d-block"
                                                          : ""
                                                      }`}
                                                    />
                                                    <ErrorMessage
                                                      name={`healthDetails[${index}].currentMedicationsList[${medIndex}].frequencyOtherText`}
                                                      component="div"
                                                      className={`invalid-feedback text-danger ${
                                                        errors.healthDetails?.[
                                                          index
                                                        ]
                                                          ?.currentMedicationsList?.[
                                                          medIndex
                                                        ]?.frequencyOtherText
                                                          ? "d-block"
                                                          : ""
                                                      }`}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="d-flex gap-3 align-items-center mb-1 mt-1">
                                                  <div className="">
                                                    <button
                                                      type="button"
                                                      className="btn btn-danger btn-md mt-1 p-1 px-2"
                                                      onClick={() =>
                                                        remove(medIndex)
                                                      }
                                                    >
                                                      {"  "}Remove
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                          <div className="ml-2 mb-2 mt-1">
                                            <button
                                              type="button"
                                              className="btn btn-primary btn-md p-1 px-2 mt-1 col-md-16 ml-2 "
                                              onClick={() =>
                                                push({
                                                  name: "",
                                                  dosage: "",
                                                  frequency: "",
                                                })
                                              }
                                            >
                                              <i className="mdi mdi-tray-plus me-1"></i>
                                              Add Medication
                                            </button>
                                          </div>
                                        </>
                                      )}
                                    </FieldArray>
                                  )}
                                </Row>

                                <Row className="mt-2 mb-1 width-radio-vol">
                                  <Label className="form-label">
                                    {"\u2022"} Do you have any medical
                                    conditions?
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.medCondition &&
                                        touched.healthDetails?.[index]
                                          ?.medCondition
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col md="5">
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].medCondition`}
                                              value="yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].medCondition`}
                                              value="no"
                                              className="me-1"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].medConditionText`,
                                                  ""
                                                );
                                              }}
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].medCondition`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails?.[index]
                                    ?.medCondition === "yes" && (
                                    <div className="col-md-10 mb-3">
                                      <Field
                                        name={`healthDetails[${index}].medConditionText`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.healthDetails?.[index]
                                            ?.medConditionText &&
                                          touched.healthDetails?.[index]
                                            ?.medConditionText
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`healthDetails[${index}].medConditionText`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}
                                </Row>
                                <Row>
                                  <div className="d-flex col-md-10">
                                    <h6> If the account holder is women</h6>
                                    <span className="text-danger">*</span>
                                  </div>
                                </Row>
                                <Row className="mt-1 mb-3  width-radio">
                                  <Label className="form-label fw-normal">
                                    {"\u2022"} Are you expecting a baby? If Yes,
                                    do you want to share any information:
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.expectingBaby &&
                                        touched.expectingBaby
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].expectingBaby`}
                                              value="yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].expectingBaby`}
                                              value="no"
                                              className="me-1"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].babyInfo`,
                                                  ""
                                                );
                                              }}
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].expectingBaby`}
                                      component="div"
                                      className={`invalid-feedback text-danger ${
                                        errors.healthDetails?.[index]
                                          ?.expectingBaby
                                          ? "d-block"
                                          : ""
                                      }`}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails?.[index]
                                    ?.expectingBaby === "yes" && (
                                    <div className="col-md-10 mb-3">
                                      <Field
                                        name={`healthDetails[${index}].babyInfo`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.healthDetails?.[index]
                                            ?.babyInfo &&
                                          touched.healthDetails?.[index]
                                            ?.babyInfo
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`healthDetails[${index}].babyInfo`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}
                                </Row>
                                <Row className="mt-1 mb-1 width-radio">
                                  <Label className="form-label fw-normal">
                                    {"\u2022"} Do you have any concern about
                                    your wellness as a woman? Please feel free
                                    to:
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col md="5">
                                    <fieldset
                                      className={
                                        errors.healthDetails?.[index]
                                          ?.womanWellness &&
                                        touched.healthDetails?.[index]
                                          ?.womanWellness
                                          ? " is-invalid"
                                          : ""
                                      }
                                    >
                                      <Row>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].womanWellness`}
                                              value="yes"
                                              className="me-1"
                                            />
                                            Yes
                                          </Label>
                                        </Col>
                                        <Col>
                                          <Label
                                            check
                                            className="d-flex align-items-center fw-normal"
                                          >
                                            <Field
                                              type="radio"
                                              name={`healthDetails[${index}].womanWellness`}
                                              value="no"
                                              className="me-1"
                                              onClick={() => {
                                                setFieldValue(
                                                  `healthDetails[${index}].womanWellnessInformation`,
                                                  ""
                                                );
                                              }}
                                            />
                                            No
                                          </Label>
                                        </Col>
                                      </Row>
                                    </fieldset>
                                    <ErrorMessage
                                      name={`healthDetails[${index}].womanWellness`}
                                      component="div"
                                      className="invalid-feedback text-danger"
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  {values.healthDetails?.[index]
                                    ?.womanWellness === "yes" && (
                                    <div className="col-md-10 mb-2 mt-2">
                                      <Field
                                        name={`healthDetails[${index}].womanWellnessInformation`}
                                        type="text"
                                        className={
                                          " form-control" +
                                          (errors.healthDetails?.[index]
                                            ?.womanWellnessInformation &&
                                          touched.healthDetails?.[index]
                                            ?.womanWellnessInformation
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder="Enter your response"
                                      />
                                      <ErrorMessage
                                        name={`healthDetails[${index}].womanWellnessInformation`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  )}
                                </Row>

                                {index !== values.healthDetails.length - 1 && (
                                  <div className="full-width-hr"></div>
                                )}
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr></hr>
                    <div className="activities-info">
                      <Row>
                        <Col md="3">
                          <h4>Activities</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-6">
                            {values.activities.map((member, index) => (
                              <div
                                key={`${index}-${member.uci}`}
                                className="mb-4"
                              >
                                <h5> {member?.memberName}</h5>
                                <Col md="9">
                                  <Row>
                                    <div className="col-md-10 mb-3">
                                      <Label
                                        for={`activities.${index}.sleepActivity`}
                                        className="form-label"
                                      >
                                        {"\u2022"} Sleep activity (hrs/night)
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Select
                                        id={`activities.${index}.sleepActivity`}
                                        name={`activities.${index}.sleepActivity`}
                                        options={sleepActivityList}
                                        value={sleepActivityList?.find(
                                          option =>
                                            option.value ===
                                            values?.activities[index]
                                              ?.sleepActivity
                                        )}
                                        onChange={option => {
                                          setFieldValue(
                                            `activities.${index}.sleepActivity`,
                                            option.value
                                          );
                                        }}
                                        className={
                                          errors.activities?.[index]
                                            ?.sleepActivity &&
                                          touched.activities?.[index]
                                            ?.sleepActivity
                                            ? "is-invalid"
                                            : ""
                                        }
                                        placeholder="Select sleep activity"
                                      />
                                      <ErrorMessage
                                        name={`activities.${index}.sleepActivity`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Row>
                                  <Row className="width-radio mb-3">
                                    <Label className="form-label">
                                      {"\u2022"} Quality of sleep
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Col md="10">
                                      <fieldset
                                        className={
                                          errors.activities?.[index]
                                            ?.sleepQuality &&
                                          touched.activities?.[index]
                                            ?.sleepQuality
                                            ? " is-invalid"
                                            : ""
                                        }
                                      >
                                        <div className="d-flex gap-5 align-items-center">
                                          <div>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepQuality`}
                                                value="Excellent"
                                                className="me-1"
                                              />
                                              Excellent
                                            </Label>
                                          </div>
                                          <div>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepQuality`}
                                                value="Good"
                                                className="me-1"
                                              />
                                              Good
                                            </Label>
                                          </div>
                                          <div>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepQuality`}
                                                value="Fair"
                                                className="me-1"
                                              />
                                              Fair
                                            </Label>
                                          </div>
                                          <div>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepQuality`}
                                                value="Poor"
                                                className="me-1"
                                              />
                                              Poor
                                            </Label>
                                          </div>
                                        </div>
                                      </fieldset>
                                      <ErrorMessage
                                        name={`activities.${index}.sleepQuality`}
                                        component="div"
                                        className="invalid-feedback text-danger"
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mt-2 mb-3 width-radio-vol">
                                    <Label className="form-label">
                                      {"\u2022"} Any Issues with sleep?
                                      (difficulty falling/staying asleep, etc):
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Col md="6">
                                      <fieldset
                                        className={
                                          errors.activities?.[index]
                                            ?.sleepIssues &&
                                          touched.activities?.[index]
                                            ?.sleepIssues
                                            ? " is-invalid"
                                            : ""
                                        }
                                      >
                                        <Row>
                                          <Col>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepIssues`}
                                                value="yes"
                                                className="me-1"
                                              />
                                              Yes
                                            </Label>
                                          </Col>
                                          <Col>
                                            <Label
                                              check
                                              className="d-flex align-items-center fw-normal"
                                            >
                                              <Field
                                                type="radio"
                                                name={`activities.${index}.sleepIssues`}
                                                value="no"
                                                className="me-1"
                                                onClick={() => {
                                                  setFieldValue(
                                                    `activities.${index}.sleepIssuesText`,
                                                    ""
                                                  );
                                                }}
                                              />
                                              No
                                            </Label>
                                          </Col>
                                        </Row>
                                      </fieldset>
                                      <ErrorMessage
                                        name={`activities.${index}.sleepIssues`}
                                        component="div"
                                        className="invalid-feedback text-danger"
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    {values?.activities[index]?.sleepIssues ===
                                      "yes" && (
                                      <div className="col-md-12 mb-3">
                                        <Label
                                          className="form-label width-radio-vol2"
                                          for={`activities.${index}.sleepIssuesText`}
                                        >
                                          {"\u2022"} If Yes, what are your
                                          issues with sleep? (ie. Nightmares,
                                          Sleepwalking, Waking up in middle of
                                          night, early morning awakenings, etc)
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <Field
                                          name={`activities.${index}.sleepIssuesText`}
                                          type="text"
                                          className={
                                            " form-control" +
                                            (errors.activities?.[index]
                                              ?.sleepIssuesText &&
                                            touched.activities?.[index]
                                              ?.sleepIssuesText
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Enter your response"
                                        />
                                        <ErrorMessage
                                          name={`activities.${index}.sleepIssuesText`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                    )}
                                  </Row>
                                </Col>

                                {index !== values.activities.length - 1 && (
                                  <div className="full-width-hr"></div>
                                )}
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button type="submit" color="primary">
                           Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntakeHealthInfo));
