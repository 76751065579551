import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import emptyContacts from "../../assets/images/emptyContact.png";
import Breadcrumbs from "components/Common/Breadcrumb";
import Pagination from "components/pagination/Pagination";
import Limit from "components/pagination/Limit";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  PAGE,
  SIZE_PER_PAGE,
  TOTAL_SIZE,
  capitalizeEachWord,
  capitalizeFirstLetter,
} from "constants/constants";
import moment from "moment";
import { setShowPageLoader } from "store/actions";
import {
  formKey,
  subFormKeyScreening,
  subFormKeyIntake,
} from "constants/referenceNotes";

class ReferenceNotesDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      page: PAGE,
      searchTerm: "",
      referenceNotesList: [],
      modal: false,
      activeTab: "0",
      selectedClient: null,
      form: "",
      subForm: "",
      note: "",
      addingReferenceNotes: false,
      showModalLoader: false,
      selectedFormKey: "",
      selectedSubFormKey: "",
      sortField: "createdAt",
      sortOrder: "desc",
    };
  }

  formMapping = {
    [formKey.SCREENING]: subFormKeyScreening,
    [formKey.INTAKE]: subFormKeyIntake,
  };

  handleFormKeyChange = event => {
    this.setState({
      selectedFormKey: event.target.value,
      selectedSubFormKey: "",
    });
  };

  handleSubFormKeyChange = event => {
    this.setState({
      selectedSubFormKey: event.target.value,
    });
  };

  toggleModal = client => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      selectedClient: prevState.modal ? null : client,
      activeTab: "screening",
    }));
  };
  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSave = async () => {
    const { note, selectedFormKey, selectedSubFormKey } = this.state;
    const { selectedClient } = this.state;
    this.setState({ showModalLoader: true });
    try {
      // Make API call to create reference note
      const referenceNotesData = {
        form: selectedFormKey,
        subForm: selectedSubFormKey,
        note,
      };
      const response = await axiosPost("/reference-notes", {
        clientId: selectedClient.clientId,
        clientName: selectedClient.clientName,
        referenceNotes: referenceNotesData,
      });
      if (response.status) {
        this.setState({
          form: "",
          subForm: "",
          note: "",
          addingReferenceNotes: false,
        });
        // Handle success
        toast.success("Reference note added successfully");
        // Close the modal
        this.toggleModal();
        // Refetch reference notes to update the list
        this.fetchReferenceNotes();
      }
      this.setState({ showModalLoader: false });
    } catch (error) {
      // Handle error
      this.setState({ showModalLoader: false });
      toast.error("Failed to add reference note", error);
    }
  };

  fetchReferenceNotes = async () => {
    const { showPageLoader } = this.props;
    const { size, page, searchTerm, sortField, sortOrder } = this.state;
    try {
      showPageLoader(true);
      const response = await axiosGet(
        `/add-client?page=${page}&limit=${size}&searchString=${searchTerm}&sortField=${sortField}&sortOrder=${sortOrder}`
      );
      if (response.status) {
        const referenceNotesList = response.data.results.map(item => {
          const latestNote =
            item.referenceNotes.length > 0
              ? item.referenceNotes[item.referenceNotes.length - 1]
              : null;
          const clientName = `${item.screeningForm.generalInformation.firstName} ${item.screeningForm.generalInformation.middleName} ${item.screeningForm.generalInformation.lastName}`;
          const refnotes = item.referenceNotes.map(note => {
            return {
              form: note.form,
              subForm: note.subForm,
              note: note.note,
              createdAt: note.createdAt,
              createdByUsername: note.createdByUsername,
              // Add other properties as needed
            };
          });
          return {
            clientUci: item.screeningForm.generalInformation.uci,
            clientId: item._id,
            clientName: clientName,
            referenceNotes: refnotes,
            latestCreatedByUsername: latestNote
              ? latestNote.createdByUsername
              : "",
            latestCreatedAt: latestNote ? latestNote.createdAt : "",

            // Add other properties as needed
          };
        });
        this.setState({
          referenceNotesList: referenceNotesList,
          totalSize: response?.data?.totalResults,
        });
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      toast.error("Error fetching reference notes", error);
    }
  };

  handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      this.setState(
        { sortField, sortOrder, page: 1 },
        this.fetchReferenceNotes
      );
    }
  };

  handlePageClick = selectedPage => {
    this.setState({ page: selectedPage }, () => {
      this.fetchReferenceNotes();
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.fetchReferenceNotes();
    });
  };

  componentDidMount = () => {
    this.fetchReferenceNotes();
  };
  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ referenceNotesList: [] }, function () {
        this.fetchReferenceNotes();
      });
    }, WAITING_TIMING);
  };

  searchReferenceNotes = event => {
    const { value } = event.target;
    this.setState(
      {
        searchTerm: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    const {
      referenceNotesList,
      modal,
      selectedClient,
      showModalLoader,
      selectedFormKey,
      selectedSubFormKey,
    } = this.state;
    const subForms = selectedFormKey ? this.formMapping[selectedFormKey] : {};

    const columns = [
      {
        dataField: "clientUci",
        text: "Client Uci",
        sort: true,
        formatter: cellContent => {
          if (cellContent.length === 8) {
            return `${cellContent.slice(0, 4)}-${cellContent.slice(4, 8)}`;
          } else if (cellContent.length === 10) {
            return `${cellContent.slice(0, 2)}-${cellContent.slice(
              2,
              6
            )}-${cellContent.slice(6, 10)}`;
          } else {
            return cellContent;
          }
        },
      },
      {
        dataField: "clientName",
        text: "Client Name",
        sort: true,
        formatter: cellContent => {
          return <div>{capitalizeEachWord(cellContent)}</div>;
        },
      },
      {
        dataField: "latestCreatedByUsername",
        text: "Latest Created By",
        sort: true,
      },
      {
        dataField: "latestCreatedAt",
        text: "Latest Date of Creation",
        sort: true,
        formatter: cell => moment(cell).format("MM-DD-YYYY HH:mm:ss"),
      },
      {
        dataField: "action",
        text: "Action",
        formatter: (cell, row) => (
          <span className="text-primary fs-5">
            <i
              className="mdi mdi-eye me-1"
              onClick={() => this.toggleModal(row)}
            />
          </span>
        ),
      },
    ];

    // Render your table using the 'columns' array
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              title="Reference Notes"
              breadcrumbItem="Reference Notes List"
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col sm="3">
                      <div className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchTerm}
                            onChange={e => {
                              this.searchReferenceNotes(e);
                            }}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchReferenceNotes(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                    </Col>
                    <div className="d-flex justify-content-center">
                      <img
                        src={emptyContacts}
                        alt="empty-conatct"
                        className={`${
                          referenceNotesList.length === 0 ? "" : "display-none"
                        }`}
                      ></img>
                    </div>
                    <div
                      className={`table-responsive table-striped ${
                        referenceNotesList.length === 0 ? "display-none" : ""
                      }`}
                    >
                      <BootstrapTable
                        remote={{ sort: true }}
                        keyField="clientId"
                        data={referenceNotesList}
                        columns={columns}
                        bordered={false}
                        striped={false}
                        responsive
                        onTableChange={this.handleTableChange}
                      />
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <Limit
                          value={this.state.size}
                          onChange={this.handleLimitChange}
                        />
                        <Pagination
                          totalSize={this.state.totalSize}
                          handlePageClick={this.handlePageClick}
                          currentPage={this.state.page - 1}
                          currentSize={this.state.size}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={modal}
          toggle={this.toggleModal}
          className="modal-dialog modal-lg modal-dialog-centered"
        >
          {selectedClient && (
            <React.Fragment>
              <ModalHeader>
                <h5 className="modal-title">
                  {capitalizeEachWord(selectedClient.clientName)} Notes
                </h5>
                <button
                  type="button"
                  className="close"
                  onClick={this.toggleModal}
                >
                  <span>&times;</span>
                </button>
              </ModalHeader>
              <ModalBody>
                <div>
                  <Nav tabs fill>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "screening" ? "active" : ""
                        }
                        onClick={() => {
                          this.toggleTab("screening");
                        }}
                      >
                        Screening
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "intake" ? "active" : ""
                        }
                        onClick={() => {
                          this.toggleTab("intake");
                        }}
                      >
                        Intake
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "settlement" ? "active" : ""
                        }
                        onClick={() => {
                          this.toggleTab("settlement");
                        }}
                      >
                        Settlement
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "shelter" ? "active" : ""
                        }
                        onClick={() => {
                          this.toggleTab("shelter");
                        }}
                      >
                        Shelter
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          this.state.activeTab === "orientation" ? "active" : ""
                        }
                        onClick={() => {
                          this.toggleTab("orientation");
                        }}
                      >
                        Orientation
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={this.state.activeTab}
                    className="mt-3 ms-2"
                    style={{ maxHeight: "50vh", overflowY: "auto" }}
                  >
                    <TabPane tabId="screening">
                      {selectedClient.referenceNotes &&
                        selectedClient.referenceNotes
                          .filter(note => note.form === "screening")
                          .sort(
                            (a, b) =>
                              new Date(a.createdAt) - new Date(b.createdAt)
                          )
                          .map((note, index) => {
                            return (
                              <div key={index} className="border p-2 mb-2">
                                <p>
                                  <strong>
                                    {capitalizeFirstLetter(note.subForm)}
                                  </strong>
                                </p>
                                <p>
                                  <span className="fw-bold">Note:</span>
                                  <span className="fw-light"> {note.note}</span>
                                </p>
                                <p>
                                  <div className="fw-bold">
                                    <span className="fw-bold">
                                      Created By:{" "}
                                    </span>
                                    <span className="fw-light">
                                      {note.createdByUsername}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">
                                      Created At:{" "}
                                    </span>
                                    <span className="fw-light">
                                      {moment(note.createdAt).format(
                                        "MM-DD-YYYY HH:mm:ss"
                                      )}
                                    </span>
                                  </div>
                                </p>
                              </div>
                            );
                          })}
                      {selectedClient.referenceNotes &&
                        selectedClient.referenceNotes.filter(
                          note => note.form === "screening"
                        ).length === 0 && (
                          <div className="text-center">
                            <img
                              src={emptyContacts}
                              alt="empty-contact"
                              className="img-fluid"
                              style={{ maxWidth: "200px" }}
                            />
                            <p>No screening notes available.</p>
                          </div>
                        )}
                    </TabPane>
                    <TabPane tabId="intake">
                      {selectedClient.referenceNotes &&
                        selectedClient.referenceNotes
                          .filter(note => note.form === "intake")
                          .sort(
                            (a, b) =>
                              new Date(a.createdAt) - new Date(b.createdAt)
                          )
                          .map((note, index) => {
                            return (
                              <div key={index} className="border p-2 mb-2">
                                <p>
                                  <strong>
                                    {capitalizeFirstLetter(note.subForm)}
                                  </strong>
                                </p>
                                <p>
                                  <span className="fw-bold">Note:</span>
                                  <span className="fw-light"> {note.note}</span>
                                </p>
                                <p>
                                  <div className="fw-bold">
                                    <span className="fw-bold">
                                      Created By:{" "}
                                    </span>
                                    <span className="fw-light">
                                      {note.createdByUsername}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="fw-bold">
                                      Created At:{" "}
                                    </span>
                                    <span className="fw-light">
                                      {moment(note.createdAt).format(
                                        "MM-DD-YYYY HH:mm:ss"
                                      )}
                                    </span>
                                  </div>
                                </p>
                              </div>
                            );
                          })}
                      {selectedClient.referenceNotes &&
                        selectedClient.referenceNotes.filter(
                          note => note.form === "intake"
                        ).length === 0 && (
                          <div className="text-center">
                            <img
                              src={emptyContacts}
                              alt="empty-contact"
                              className="img-fluid"
                              style={{ maxWidth: "200px" }}
                            />
                            <p>No intake notes available.</p>
                          </div>
                        )}
                    </TabPane>
                    <TabPane tabId="settlement">
                      {selectedClient.referenceNotes &&
                        selectedClient.referenceNotes
                          .filter(note => note.form === "settlement")
                          .sort(
                            (a, b) =>
                              new Date(a.createdAt) - new Date(b.createdAt)
                          )
                          .map((note, index) => {
                            return (
                              <div key={index} className="border p-2 mb-2">
                                <p>
                                  <strong>
                                    {capitalizeFirstLetter(note.subForm)}
                                  </strong>
                                </p>
                                <p>
                                  <span className="fw-bold">Note:</span>
                                  <span className="fw-light"> {note.note}</span>
                                </p>
                                <p>
                                  <small>
                                    <strong>Created By:</strong>{" "}
                                    {note.createdByUsername}
                                  </small>
                                </p>
                                <p>
                                  <small>
                                    <strong>Created At:</strong>{" "}
                                    {moment(note.createdAt).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </small>
                                </p>
                              </div>
                            );
                          })}
                      {selectedClient.referenceNotes &&
                        selectedClient.referenceNotes.filter(
                          note => note.form === "settlement"
                        ).length === 0 && (
                          <div className="text-center">
                            <img
                              src={emptyContacts}
                              alt="empty-contact"
                              className="img-fluid"
                              style={{ maxWidth: "200px" }}
                            />
                            <p>No intake notes available.</p>
                          </div>
                        )}
                    </TabPane>
                    <TabPane tabId="shelter">
                      {selectedClient.referenceNotes &&
                        selectedClient.referenceNotes
                          .filter(note => note.form === "shelter")
                          .sort(
                            (a, b) =>
                              new Date(a.createdAt) - new Date(b.createdAt)
                          )
                          .map((note, index) => {
                            return (
                              <div key={index} className="border p-2 mb-2">
                                <p>
                                  <strong>
                                    {capitalizeFirstLetter(note.subForm)}
                                  </strong>
                                </p>
                                <p>
                                  <span className="fw-bold">Note:</span>
                                  <span className="fw-light"> {note.note}</span>
                                </p>
                                <p>
                                  <small>
                                    <strong>Created By:</strong>{" "}
                                    {note.createdByUsername}
                                  </small>
                                </p>
                                <p>
                                  <small>
                                    <strong>Created At:</strong>{" "}
                                    {moment(note.createdAt).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </small>
                                </p>
                              </div>
                            );
                          })}
                      {selectedClient.referenceNotes &&
                        selectedClient.referenceNotes.filter(
                          note => note.form === "shelter"
                        ).length === 0 && (
                          <div className="text-center">
                            <img
                              src={emptyContacts}
                              alt="empty-contact"
                              className="img-fluid"
                              style={{ maxWidth: "200px" }}
                            />
                            <p>No intake notes available.</p>
                          </div>
                        )}
                    </TabPane>

                    <TabPane tabId="orientation">
                      {selectedClient.referenceNotes &&
                        selectedClient.referenceNotes
                          .filter(note => note.form === "orientation")
                          .sort(
                            (a, b) =>
                              new Date(a.createdAt) - new Date(b.createdAt)
                          )
                          .map((note, index) => {
                            return (
                              <div key={index} className="border p-2 mb-2">
                                <p>
                                  <strong>
                                    {capitalizeFirstLetter(note.subForm)}
                                  </strong>
                                </p>
                                <p>
                                  <span className="fw-bold">Note:</span>
                                  <span className="fw-light"> {note.note}</span>
                                </p>
                                <p>
                                  <small>
                                    <strong>Created By:</strong>{" "}
                                    {note.createdByUsername}
                                  </small>
                                </p>
                                <p>
                                  <small>
                                    <strong>Created At:</strong>{" "}
                                    {moment(note.createdAt).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </small>
                                </p>
                              </div>
                            );
                          })}
                      {selectedClient.referenceNotes &&
                        selectedClient.referenceNotes.filter(
                          note => note.form === "orientation"
                        ).length === 0 && (
                          <div className="text-center">
                            <img
                              src={emptyContacts}
                              alt="empty-contact"
                              className="img-fluid"
                              style={{ maxWidth: "200px" }}
                            />
                            <p>No intake notes available.</p>
                          </div>
                        )}
                    </TabPane>
                  </TabContent>
                </div>
              </ModalBody>
              <hr></hr>
              <div className="m-3">
                {!this.state.addingReferenceNotes && (
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({ addingReferenceNotes: true })
                    }
                  >
                    Add Reference Notes
                  </Button>
                )}
                {this.state.addingReferenceNotes && (
                  <React.Fragment>
                    <div className="d-flex align-items-center mb-3">
                      <h5 className="me-3">Add New Reference Note</h5>
                      <Button
                        color="secondary"
                        className="ms-auto"
                        onClick={() =>
                          this.setState({ addingReferenceNotes: false })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                    {/* <div className="form-group mb-3">
                      <Label htmlFor="form">Form</Label>
                      <FormControl
                        id="form"
                        placeholder="Enter Form"
                        name="form"
                        value={this.state.form}
                        onChange={this.handleInputChange}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <Label htmlFor="subForm">Subform</Label>
                      <FormControl
                        id="subForm"
                        placeholder="Enter Subform"
                        name="subForm"
                        value={this.state.subForm}
                        onChange={this.handleInputChange}
                        className="form-control"
                      />
                    </div> */}
                    <div className="">
                      <label>Select Form:</label>
                      <select
                        className="form-control mb-3"
                        value={selectedFormKey}
                        onChange={this.handleFormKeyChange}
                      >
                        <option value="">Select Form</option>
                        <option value={formKey.SCREENING}>Screening</option>
                        <option value={formKey.INTAKE}>Intake</option>
                      </select>
                    </div>
                    {selectedFormKey && (
                      <div>
                        <label>Select SubForm:</label>
                        <select
                          className="form-control mb-3"
                          value={selectedSubFormKey}
                          onChange={this.handleSubFormKeyChange}
                        >
                          <option value="">Select SubForm</option>
                          {Object.entries(subForms).map(([key, value]) => (
                            <option key={key} value={value}>
                              {capitalizeFirstLetter(value)}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className="form-group mb-3">
                      <Label htmlFor="note">Note</Label>
                      <FormControl
                        as="textarea"
                        id="note"
                        placeholder="Enter Note"
                        name="note"
                        value={this.state.note}
                        onChange={this.handleInputChange}
                        className="form-control"
                        style={{ resize: "none" }}
                      />
                    </div>
                    <Button color="primary" onClick={this.handleSave}>
                      Save
                    </Button>
                  </React.Fragment>
                )}
                <Button
                  color="danger"
                  className="ms-2"
                  onClick={this.toggleModal}
                >
                  Close
                </Button>
              </div>
            </React.Fragment>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  // unreadCount: state.Notifications.unreadCount || 0,
});

const mapDispatchToProps = dispatch => ({
  // updateUnreadCount: count => dispatch(updateUnreadCount(count)),
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferenceNotesDashboard);

// export default withTranslation()(ReferenceNotesDashboard);
