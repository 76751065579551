import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { setShowPageLoader } from "store/actions";
import { axiosPatch, axiosPost } from "helpers/api_helpers";
import { toast } from "react-toastify";
import CustomDatePicker from "components/Common/DateField";

const validationSchema = Yup.object().shape({
  memberDetails: Yup.array().of(
    Yup.object().shape({
      date: Yup.date().required("Required"),
      name: Yup.string().required("Required"),
      agency: Yup.string().required("Required"),
    })
  ),
});

class Employement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
      url: "",
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;

    try {
      const leadClientData = {
        memberHouseholdId: clientData.screeningForm?.generalInformation?.memberHouseholdId,
        uci: clientData.screeningForm?.generalInformation?.uci,
        firstName: clientData.screeningForm?.generalInformation?.firstName,
        lastName: clientData.screeningForm?.generalInformation?.lastName,
        middleName: clientData.screeningForm?.generalInformation?.middleName,
        gender: clientData.screeningForm?.generalInformation?.gender,
        dateOfBirth: clientData.screeningForm?.generalInformation?.dateOfBirth,
        relation: "Self",
      };
      // Create array with lead client data and all members' data
      const allMembersData = [
        leadClientData,
        ...clientData.screeningForm?.generalInformation?.members,
      ];

      this.setState({
        allMembers: allMembersData,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  handleFileUpload = async (event, setFieldValue, fieldName) => {
    const file = event.target.files?.[0];

    let url = "";
    setFieldValue(fieldName, event.target.value);
    this.setState({ uploadedFile: file }, async () => {
      url = await this.handleFileAddition();
      // Set the field value with the obtained URL
      setFieldValue(fieldName, url);
    });
    return url;
  };

  handleFileAddition = async () => {
    try {
      const { uploadedFile } = this.state;

      const formData = new FormData();
      formData.append("file", uploadedFile);

      // Upload image
      const uploadResponse = await axiosPost(`upload`, formData);
      let url = uploadResponse?.data?.destination;
      url = url.replace(/["“”]/g, ""); // to remove ngrok "" in url
      this.setState({ url: url });
      return url;
    } catch (error) {
      console.error("error at handleFileAddition:", error);
    }
  };

  handleMemberSelect = (selectedOptions, values, setFieldValue) => {
    const selectedValues = selectedOptions.map(option => {
      const matchedRestriction = values.memberDetails.find(
        member => member.memberId === option.value
      );
      if (matchedRestriction) {
        return matchedRestriction;
      }
      return (
        {
          memberId: option.value,
          memberName: option.label,
          date: "",
          name: "",
          agency: "",
          resume: "",
        }
      );
    });
    setFieldValue("memberDetails", selectedValues);
  };

  handleAddShelterEmploymentData = async employmentInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/shelter/employment/${clientData?.id}`,
        employmentInfo
      );
      if (response?.status) {
        toast.success(
          response?.message || "Employment information stored successfully!"
        );
        handleGetClient();
        toggle("5");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      employmentInfo: {
        memberDetails:
          values?.memberDetails.map(detail => ({
            memberId: detail?.memberId,
            date: detail?.date,
            name: detail?.name,
            agency: detail?.agency,
            ...(detail.resume && { resume: detail?.resume }),
          })) || [],
      },
    };
    this.handleAddShelterEmploymentData(data);
  };

  render() {
    const { allMembers } = this.state;

    const { clientData } = this.props;

      const initalEmploymentDetails =
      clientData?.shelter?.employment?.memberDetails
        ?.map(memberDetail => {
          const foundMember = allMembers.find(m => m.memberHouseholdId === memberDetail.memberId);
          if (foundMember) {
            return {
              ...memberDetail,
              memberName: `${foundMember.firstName || ""} ${
                foundMember.middleName || ""
              } ${foundMember.lastName || ""}`.trim(),
              date: new Date(memberDetail?.date).toISOString().split("T")[0],
            name: memberDetail?.name,
            agency: memberDetail?.agency,
            resume: memberDetail?.resume?.replace(/["“”]/g, "")
            };
  
          }
          return null;
        })
        .filter(Boolean) || [];
    const initialValues = {
      // memberDetails: clientData?.shelter?.employment?.memberDetails?.length > 0 &&
      //   clientData?.shelter?.employment?.memberDetails.map(memberDetail => {
      //     const matchedMember = allMembers.find((client) => client.uci === memberDetail.uci)|| {}
      //     return {
      //       uci: matchedMember?.uci || "",
      //       memberName: `${matchedMember?.firstName} ${matchedMember?.middleName} ${matchedMember?.lastName}` || "",
      //       date: memberDetail?.date
      //         ? new Date(memberDetail?.date).toISOString().split("T")[0]
      //         : "" || "",
      //       name: memberDetail?.name || "",
      //       agency: memberDetail?.agency || "",
      //       resume: memberDetail?.resume?.replace(/["“”]/g, "") || "",
      //     }
      //   }) || [],
      memberDetails: initalEmploymentDetails,
    };

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Employment Supports</h4>
                        </Col>
                        <Col md="9">
                          <Label>Select Members</Label>
                          <Select
                            className="col-md-4"
                            id="memberSelect"
                            name="memberSelect"
                            value={values.memberDetails.map(
                              member => ({
                                value: member.memberId,
                                label: member.memberName,
                              })
                            )}
                            options={allMembers.map(member => ({
                              value: member.memberHouseholdId,
                              label: `${member.firstName || ""} ${
                                member.middleName || ""
                              } ${member.lastName || ""}`.trim(),
                            }))}
                            isMulti
                            onChange={selectedOption => {
                              this.handleMemberSelect(
                                selectedOption,
                                values,
                                setFieldValue
                              );
                            }}
                          />
                          <div className="mb-3">
                            <FieldArray name="memberDetails">
                              {({ insert, remove, push }) => (
                                <div className="mt-2">
                                  {values?.memberDetails?.length > 0 &&
                                    values?.memberDetails?.map(
                                      (member, index) => (
                                        <div key={index} className="">
                                          <div className="mt-4">
                                            <label className="form-label">
                                              <h4>{member?.memberName}</h4>
                                            </label>
                                          </div>
                                          <div className="d-flex">
                                            <div className="col-md-4 mb-3 me-3">
                                              <label
                                                htmlFor={`name${index}`}
                                                className="form-label"
                                              >
                                                Name
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <Field
                                                id={`name${index}`}
                                                name={`memberDetails.${index}.name`}
                                                placeholder="Enter Name"
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors?.memberDetails &&
                                                  errors?.memberDetails[
                                                    index
                                                  ] &&
                                                  errors?.memberDetails[index]
                                                    ?.name &&
                                                  touched?.memberDetails &&
                                                  touched?.memberDetails[
                                                    index
                                                  ] &&
                                                  touched?.memberDetails[index]
                                                    ?.name
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name={`memberDetails.${index}.name`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="col-md-4 mb-3 me-3">
                                              <label
                                                htmlFor={`agency${index}`}
                                                className="form-label"
                                              >
                                                Agency
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              <Field
                                                id={`agency${index}`}
                                                name={`memberDetails.${index}.agency`}
                                                placeholder="Enter Agency"
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (errors?.memberDetails &&
                                                  errors?.memberDetails[
                                                    index
                                                  ] &&
                                                  errors?.memberDetails[index]
                                                    ?.agency &&
                                                  touched?.memberDetails &&
                                                  touched?.memberDetails[
                                                    index
                                                  ] &&
                                                  touched?.memberDetails[index]
                                                    ?.agency
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name={`memberDetails.${index}.agency`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="col-md-3 mb-3">
                                              <label
                                                htmlFor={`date${index}`}
                                                className="form-label"
                                              >
                                                Date
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              {/* <Field
                                                id={`date${index}`}
                                                name={`memberDetails.${index}.date`}
                                                placeholder="Enter date"
                                                type="date"
                                                className={
                                                  "form-control" +
                                                  (errors?.memberDetails &&
                                                  errors?.memberDetails[
                                                    index
                                                  ] &&
                                                  errors?.memberDetails[index]
                                                    ?.date &&
                                                  touched?.memberDetails &&
                                                  touched?.memberDetails[
                                                    index
                                                  ] &&
                                                  touched?.memberDetails[index]
                                                    ?.date
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              /> */}
                                              <CustomDatePicker
                                                className={
                                                  "form-control" +
                                                  (errors?.memberDetails?.[
                                                    index
                                                  ]?.date &&
                                                  touched?.memberDetails?.[
                                                    index
                                                  ]?.date
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                selected={
                                                  values?.memberDetails?.[index]
                                                    ?.date
                                                    ? values.memberDetails?.[
                                                        index
                                                      ]?.date
                                                    : null
                                                }
                                                onChange={date => {
                                                  if (date) {
                                                    setFieldValue(
                                                      `memberDetails.${index}.date`,
                                                      date
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      `memberDetails.${index}.date`,
                                                      null
                                                    );
                                                  }
                                                }}
                                                placeholderText="YYYY-MM-DD"
                                              />
                                              <ErrorMessage
                                                name={`memberDetails.${index}.date`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </div>

                                          <div className="col-md-4 mb-3">
                                            <label
                                              htmlFor={`memberDetails[${index}].resume`}
                                              className="form-label"
                                            >
                                              Resume
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            <input
                                              id={`resume${index}`}
                                              name={`memberDetails[${index}].resume`}
                                              type="file"
                                              accept=".pdf,.docx,.doc"
                                              className={
                                                "form-control" +
                                                (errors?.memberDetails &&
                                                errors?.memberDetails[index] &&
                                                errors?.memberDetails[index]
                                                  ?.resume &&
                                                touched?.memberDetails &&
                                                touched?.memberDetails[index] &&
                                                touched?.memberDetails[index]
                                                  ?.resume
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              onChange={event => {
                                                const url =
                                                  this.handleFileUpload(
                                                    event,
                                                    setFieldValue,
                                                    `memberDetails[${index}].resume`
                                                  );
                                                setFieldValue(
                                                  `memberDetails[${index}].resume`,
                                                  url
                                                );
                                              }}
                                            />
                                            <ErrorMessage
                                              name={`memberDetails[${index}].resume`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                          {member?.resume && (
                                            <a
                                              href={member?.resume}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="btn btn-primary d-inline-flex align-items-center p-2 gap-2 mt-2"
                                            >
                                              <i className="fs-4 bx bxs-file"></i>
                                              <span>Your Resume</span>
                                            </a>
                                          )}
                                          {index <
                                          values?.memberDetails?.length - 1 ? (
                                            <hr />
                                          ) : null}
                                        </div>
                                      )
                                    )}
                                </div>
                              )}
                            </FieldArray>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Employement));
