import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { InputGroup, FormControl, FormSelect } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { axiosGet, axiosPatch } from "helpers/api_helpers";
import { toast } from "react-toastify";
import emptyContacts from "../../assets/images/emptyContact.png";
import Breadcrumbs from "components/Common/Breadcrumb";
import { UserContext } from "components/UserProvider/UserProvider";
import Pagination from "components/pagination/Pagination";
import Limit from "components/pagination/Limit";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  addNotification,
  setShowPageLoader,
  updateNotification,
  updateUnreadCount,
} from "../../store/actions";
import { PAGE, SIZE_PER_PAGE, TOTAL_SIZE } from "constants/constants";
import moment from "moment";
import Select from "react-select";
class NotificationDashboard extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      size: SIZE_PER_PAGE,
      totalSize: TOTAL_SIZE,
      page: PAGE,
      searchTerm: "",
      type: "",
      notificationsList: [],
      sortOrder: "asc",
      sortField: "recipients.isRead",
    };
  }

  fetchNotification = async () => {
    const { size, page, searchTerm, type, sortField, sortOrder } = this.state;
    const { addNotifications, showPageLoader } = this.props;
    try {
      showPageLoader(true);
      const response = await axiosGet(
        `/notification?page=${page}&limit=${size}&searchString=${
          searchTerm || type
        }&sortField=${sortField}&sortOrder=${sortOrder}`
      );
      if (response.status) {
        addNotifications(response?.data?.results);
        this.setState({
          notificationsList: response?.data?.results,
          totalSize: response?.data?.totalResults,
        });
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      toast.error("error at fetch notification", error);
    }
  };

  componentDidMount = () => {
    this.fetchNotification();
  };

  markAsRead = async (notificationId, index) => {
    const {
      updateNotification,
      notifications,
      updateUnreadCount,
      showPageLoader,
    } = this.props;
    try {
      showPageLoader(true);
      // Update the notification as read
      const response = await axiosPatch(
        `/notification/${notificationId}/read`,
        {
          userId: this.context?.userData?.id,
        }
      );
      if (response.status) {
        // Update the notification as read
        const updatedNotifications = notifications.map((notification, i) => {
          if (i === index) {
            const updatedRecipients = Array.isArray(notification.recipients)
              ? notification.recipients.map(recipient => ({
                  ...recipient,
                  isRead: true,
                }))
              : { ...notification.recipients, isRead: true };
            return {
              ...notification,
              recipients: updatedRecipients,
            };
          }
          return notification;
        });
        updateNotification(updatedNotifications);

        // Update unread count
        const unreadCount = updatedNotifications.reduce(
          (count, notification) => {
            if (Array.isArray(notification.recipients)) {
              return (
                count +
                notification.recipients.filter(recipient => !recipient.isRead)
                  .length
              );
            } else {
              return count + (notification.recipients.isRead ? 0 : 1);
            }
          },
          0
        );
        updateUnreadCount(unreadCount);

        // toast.success("Notification marked as seen");
        this.fetchNotification();
      }
      showPageLoader(false);
    } catch (error) {
      showPageLoader(false);
      toast.error("Failed to mark notification as seen");
    }
  };

  handlePageClick = selectedPage => {
    // Adjust for any other necessary calculations
    this.setState({ page: selectedPage }, () => {
      this.fetchNotification();
    });
  };

  handleLimitChange = event => {
    const selectedSize = parseInt(event.target.value);
    this.setState({ size: selectedSize, page: 1 }, () => {
      this.fetchNotification();
    });
  };

  debounceSearch = () => {
    const WAITING_TIMING = 500; // Adjust the debounce delay as needed
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({ notificationsList: [] }, function () {
        this.fetchNotification();
      });
    }, WAITING_TIMING);
  };

  searchNotifications = event => {
    const { value } = event.target;
    this.setState(
      {
        searchTerm: value,
      },
      () => {
        this.debounceSearch();
      }
    );
  };

  handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      this.setState({ sortField, sortOrder, page: 1 }, this.fetchNotification);
    }
  };
  render() {
    const { notificationsList } = this.state;

    const selectOptions = [
      {
        label: "Shelter",
        value: "shelter",
      },
      {
        label: "Settlement",
        value: "settlement",
      },
      {
        label: "Orientation",
        value: "orientation",
      },
    ];

    const columns = [
      {
        dataField: "type",
        text: "Type",
        sort: true,
        formatter: (cellContent, row) => {
          let iconClassName = "";
          // Determine icon and text based on type
          switch (row.type) {
            case "Client approval:approved":
              iconClassName = "bx bx-check-circle fs-5 text-success";
              break;
            case "Client approval:declined":
              iconClassName = "bx bx-x-circle fs-5 text-danger";
              break;
            // Add more cases for other types as needed
            default:
              iconClassName = "bx bx-info-circle fs-5 text-info";
              break;
          }
          return (
            <div className="d-flex align-items-center">
              <i className={iconClassName} style={{ marginRight: "5px" }} />
              {row.type}
            </div>
          );
        },
      },
      {
        dataField: "message",
        text: "Message",
        sort: true,
      },
      {
        dataField: "dateOfCreation",
        text: "Timestamp",
        sort: true,
        formatter: cell => moment(cell).format("MM-DD-YYYY HH:mm:ss"),
      },
      {
        dataField: "recipients.isRead",
        text: "Action",
        sort: true,
        formatter: (cell, row, rowIndex) => (
          <Button
            color="primary"
            disabled={row.recipients.isRead}
            onClick={() => this.markAsRead(row._id, rowIndex)}
          >
            {row.recipients.isRead ? (
              <span>
                <i className="bx bx-check-double fs-14" /> Mark as Read
              </span>
            ) : (
              <span className="d-flex justify-content-center align-items-center">
                <i className="bx bx-check fs-5 me-1" /> Mark as Read
              </span>
            )}
          </Button>
        ),
      },
      {
        dataField: "recipients",
        text: "Status",
        sort: true,
        formatter: (cell, row) => {
          const isRead = row.recipients.isRead; // Access the isRead property from the recipients object
          return isRead ? (
            <div className="d-flex align-items-center text-primary gap-1">
              <i className="bx bx-check-double fs-5"></i>
              <span>Seen</span>
            </div>
          ) : (
            <div className="d-flex align-items-center text-primary gap-1 text-opacity-75">
              <i className="bx bx-check fs-5"></i>
              <span>Unseen</span>
            </div>
          );
        },
      },
    ];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              title="Notifications"
              breadcrumbItem="Notification List"
            />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Col
                      md="12"
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div className="app-search d-none d-lg-block">
                        <div className="position-relative">
                          <FormControl
                            className="search-form-control"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={this.state.searchTerm}
                            onChange={this.searchNotifications}
                            onKeyDown={e => {
                              if (e.key === "Enter") {
                                this.searchNotifications(e);
                              }
                            }}
                          />
                          <span className="search-icon bx bx-search-alt" />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <Select
                          placeholder="Select department"
                          options={selectOptions}
                          onChange={option => {
                            this.setState({ type: option?.value || "" }, () => {
                              this.fetchNotification();
                            });
                          }}
                          isClearable
                        />
                      </div>
                    </Col>
                    <div className="d-flex justify-content-center">
                      <img
                        src={emptyContacts}
                        alt="empty-contact"
                        className={`${
                          notificationsList.length === 0 ? "" : "display-none"
                        }`}
                      ></img>
                    </div>
                    <div
                      className={`table-responsive table-striped ${
                        notificationsList.length === 0 ? "display-none" : ""
                      }`}
                    >
                      <BootstrapTable
                        remote={{ sort: true }}
                        keyField="_id"
                        data={notificationsList}
                        columns={columns}
                        classes={"table align-middle table-nowrap"}
                        bordered={false}
                        striped={false}
                        responsive
                        onTableChange={this.handleTableChange}
                      />
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <Limit
                          value={this.state.size}
                          onChange={this.handleLimitChange}
                        />
                        <Pagination
                          totalSize={this.state.totalSize}
                          handlePageClick={this.handlePageClick}
                          currentPage={this.state.page - 1}
                          currentSize={this.state.size}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  addNotifications: notifications => dispatch(addNotification(notifications)),
  updateNotification: data => dispatch(updateNotification(data)),
  updateUnreadCount: data => dispatch(updateUnreadCount(data)),
  showPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

const mapStateToProps = state => {
  return {
    notifications: state.Notifications.notifications || [],
    unreadCount: state.Notifications.unreadCount || 0,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NotificationDashboard));
