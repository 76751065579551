import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import { setShowPageLoader } from "store/actions";
import { formKey, shelterSubFormKey } from "constants/constants";
import { toast } from "react-toastify";
import { axiosPatch } from "helpers/api_helpers";
import CustomDatePicker from "components/Common/DateField";

const validationSchema = Yup.object().shape({
  memberDetails: Yup.array().of(
    Yup.object().shape({
      languageAssesmentDate: Yup.date().required("Required"),
      eslRegistrationDate: Yup.date().required("Required"),
      speakingProficiency: Yup.number().required("Required"),
      readingProficiency: Yup.number().required("Required"),
      writingProficiency: Yup.number().required("Required"),
      listeningProficiency: Yup.number().required("Required"),
      childrenSchoolName: Yup.string().required("Required"),
      childrenSchoolStartDate: Yup.date().required("Required"),
      childrenSchoolSupplies: Yup.boolean().required("Required"),
    })
  ),
  referenceNote: Yup.string().optional(),
});

class Education extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
    };
  }

  componentDidMount = () => {
    const { clientData } = this.props;

    try {
      const leadClientData = {
        memberHouseholdId: clientData.screeningForm?.generalInformation?.memberHouseholdId,
        uci: clientData.screeningForm?.generalInformation?.uci,
        firstName: clientData.screeningForm?.generalInformation?.firstName,
        lastName: clientData.screeningForm?.generalInformation?.lastName,
        middleName: clientData.screeningForm?.generalInformation?.middleName,
        gender: clientData.screeningForm?.generalInformation?.gender,
        dateOfBirth: clientData.screeningForm?.generalInformation?.dateOfBirth,
        relation: "Self",
      };
      // Create array with lead client data and all members' data
      const allMembersData = [
        leadClientData,
        ...clientData.screeningForm?.generalInformation?.members,
      ];

      this.setState({
        allMembers: allMembersData,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  handleMemberSelect = (selectedOptions, values, setFieldValue) => {
    const selectedValues = selectedOptions.map(option => {
      const matchedRestriction = values.memberDetails.find(
        member => member.memberId === option.value
      );
      if (matchedRestriction) {
        return matchedRestriction;
      }
      return (
        {
          memberId: option.value,
          memberName: option.label,
          languageAssesmentDate: "",
          eslRegistrationDate: "",
          speakingProficiency: "",
          readingProficiency: "",
          writingProficiency: "",
          listeningProficiency: "",
          childrenSchoolName: "",
          childrenSchoolStartDate: "",
          childrenSchoolSupplies: false,
        }
      );
    });
    setFieldValue("memberDetails", selectedValues);
  };

  handleAddShelterEducationData = async educationInfo => {
    const { clientData, setShowPageLoader, handleGetClient, toggle } =
      this.props;
    try {
      setShowPageLoader(true);
      const response = await axiosPatch(
        `/add-client/shelter/education/${clientData?.id}`,
        educationInfo
      );
      if (response?.status) {
        toast.success(
          response?.message || "Education information stored successfully!"
        );
        handleGetClient();
        toggle("4");
      } else {
        toast.error(response?.message || "Oops something went wrong!");
      }
      setShowPageLoader(false);
    } catch (error) {
      setShowPageLoader(false);
      console.error("error: ", error);
    }
  };

  handleSubmit = async values => {
    const data = {
      educationInfo: {
        memberDetails:
          values?.memberDetails.map(detail => ({
            memberId: detail?.memberId,
            languageAssesmentDate: detail?.languageAssesmentDate,
            eslRegistrationDate: detail?.eslRegistrationDate,
            speakingProficiency: detail?.speakingProficiency,
            readingProficiency: detail?.readingProficiency,
            writingProficiency: detail?.writingProficiency,
            listeningProficiency: detail?.listeningProficiency,
            childrenSchoolName: detail?.childrenSchoolName,
            childrenSchoolStartDate: detail?.childrenSchoolStartDate,
            childrenSchoolSupplies: detail?.childrenSchoolSupplies,
          })) || [],
      },
      ...(values?.referenceNote && { referenceNotes: values?.referenceNote }),
    };
    this.handleAddShelterEducationData(data);
  };

  render() {
    const { allMembers } = this.state;

    const { clientData } = this.props;

    const referenceNotesId = clientData?.referenceNotesId;
    let refNote = "";
    if (referenceNotesId) {
      let latestNote = null;
      for (const note of referenceNotesId) {
        if (
          note.form === formKey.SHELTER &&
          note.subForm === shelterSubFormKey.EDUCATION &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
      const initalEducationDetails =
      clientData?.shelter?.education?.memberDetails
        ?.map(memberDetail => {
          const foundMember = allMembers.find(m => m.memberHouseholdId === memberDetail.memberId);
          if (foundMember) {
            return {
              ...memberDetail,
              memberName: `${foundMember.firstName || ""} ${
                foundMember.middleName || ""
              } ${foundMember.lastName || ""}`.trim(),
              languageAssesmentDate: 
             new Date(memberDetail?.languageAssesmentDate)
                  .toISOString()
                  .split("T")[0]
              ,
            eslRegistrationDate: new Date(memberDetail?.eslRegistrationDate)
                  .toISOString()
                  .split("T")[0],
            speakingProficiency: memberDetail?.speakingProficiency || 0,
            readingProficiency: memberDetail?.readingProficiency || 0,
            writingProficiency: memberDetail?.writingProficiency || 0,
            listeningProficiency: memberDetail?.listeningProficiency || 0,
            childrenSchoolName: memberDetail?.childrenSchoolName || "",
            childrenSchoolStartDate: 
               new Date(memberDetail?.childrenSchoolStartDate)
                  .toISOString()
                  .split("T")[0],
            childrenSchoolSupplies: memberDetail?.childrenSchoolSupplies || false,
            };
  
          }
          return null;
        })
        .filter(Boolean) || [];
    const initialValues = {
      memberDetails: initalEducationDetails,
      referenceNote: refNote || "",
    };

    return (
      <React.Fragment>
        <div className="general-info">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              this.handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              status,
              touched,
              formik,
              values,
              setFieldValue,
              dirty,
            }) => (
              <Form>
                <div className="personal-info">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="3">
                          <h4>Education and Language</h4>
                        </Col>
                        <Col md="9">
                          <Label>Select Members</Label>
                          <Select
                            className="col-md-4"
                            id="memberSelect"
                            name="memberSelect"
                            value={values.memberDetails.map(
                              member => ({
                                value: member.memberId,
                                label: member.memberName,
                              })
                            )}
                            options={allMembers.map(member => ({
                              value: member.memberHouseholdId,
                              label: `${member.firstName || ""} ${
                                member.middleName || ""
                              } ${member.lastName || ""}`.trim(),
                            }))}
                            isMulti
                            onChange={selectedOption => {
                              this.handleMemberSelect(
                                selectedOption,
                                values,
                                setFieldValue
                              );
                            }}
                          />
                          <ErrorMessage
                            name="memberSelect"
                            component="div"
                            className={
                              values?.memberSelect
                                ? "invalid-feedback"
                                : "text-danger"
                            }
                          />
                          <div className="mb-3">
                            <FieldArray name="memberDetails">
                              {({ insert, remove, push }) => (
                                <div className="mt-2">
                                  {values?.memberDetails?.length > 0 &&
                                    values?.memberDetails?.map(
                                      (member, index) => (
                                        <div key={index} className="">
                                          <div className="mt-4">
                                            <label className="form-label">
                                              <h4>{member?.memberName}</h4>
                                            </label>
                                          </div>
                                          <div className="col-md-4 mb-3">
                                            <label
                                              htmlFor={`languageAssesmentDate${index}`}
                                              className="form-label"
                                            >
                                              Language Assesment Date
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                            {/* <Field
                                              id={`languageAssesmentDate${index}`}
                                              name={`memberDetails.${index}.languageAssesmentDate`}
                                              placeholder="Enter date"
                                              type="date"
                                              className={
                                                "form-control" +
                                                (errors?.memberDetails &&
                                                errors?.memberDetails[index] &&
                                                errors?.memberDetails[index]
                                                  ?.languageAssesmentDate &&
                                                touched?.memberDetails &&
                                                touched?.memberDetails[index] &&
                                                touched?.memberDetails[index]
                                                  ?.languageAssesmentDate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            /> */}
                                            <CustomDatePicker
                                              className={
                                                "form-control" +
                                                (errors?.memberDetails?.[index]
                                                  ?.languageAssesmentDate &&
                                                touched?.memberDetails?.[index]
                                                  ?.languageAssesmentDate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              selected={
                                                values?.memberDetails?.[index]
                                                  ?.languageAssesmentDate
                                                  ? values.memberDetails?.[
                                                      index
                                                    ]?.languageAssesmentDate
                                                  : null
                                              }
                                              onChange={date => {
                                                if (date) {
                                                  setFieldValue(
                                                    `memberDetails.${index}.languageAssesmentDate`,
                                                    date
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    `memberDetails.${index}.languageAssesmentDate`,
                                                    null
                                                  );
                                                }
                                              }}
                                              placeholderText="YYYY-MM-DD"
                                            />
                                            <ErrorMessage
                                              name={`memberDetails.${index}.languageAssesmentDate`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                          <div className="">
                                            <div className="col-md-4 mb-3">
                                              <label
                                                htmlFor={`eslRegistrationDate${index}`}
                                                className="form-label"
                                              >
                                                ESL Registration Start Date
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                              {/* <Field
                                                id={`eslRegistrationDate${index}`}
                                                name={`memberDetails.${index}.eslRegistrationDate`}
                                                placeholder="Enter date"
                                                type="date"
                                                className={
                                                  "form-control" +
                                                  (errors?.memberDetails &&
                                                  errors?.memberDetails[
                                                    index
                                                  ] &&
                                                  errors?.memberDetails[index]
                                                    ?.eslRegistrationDate &&
                                                  touched?.memberDetails &&
                                                  touched?.memberDetails[
                                                    index
                                                  ] &&
                                                  touched?.memberDetails[index]
                                                    ?.eslRegistrationDate
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              /> */}
                                              <CustomDatePicker
                                                className={
                                                  "form-control" +
                                                  (errors?.memberDetails?.[
                                                    index
                                                  ]?.eslRegistrationDate &&
                                                  touched?.memberDetails?.[
                                                    index
                                                  ]?.eslRegistrationDate
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                selected={
                                                  values?.memberDetails?.[index]
                                                    ?.eslRegistrationDate
                                                    ? values.memberDetails?.[
                                                        index
                                                      ]?.eslRegistrationDate
                                                    : null
                                                }
                                                onChange={date => {
                                                  if (date) {
                                                    setFieldValue(
                                                      `memberDetails.${index}.eslRegistrationDate`,
                                                      date
                                                    );
                                                  } else {
                                                    setFieldValue(
                                                      `memberDetails.${index}.eslRegistrationDate`,
                                                      null
                                                    );
                                                  }
                                                }}
                                                placeholderText="YYYY-MM-DD"
                                              />
                                              <ErrorMessage
                                                name={`memberDetails.${index}.eslRegistrationDate`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="mt-2">
                                              <label className="form-label">
                                                English Proficiency (According
                                                to the ESL Assessment Scale
                                                1-8):{" "}
                                              </label>
                                            </div>
                                            <div className="d-flex">
                                              <div className="col-md-2 mb-3 me-3">
                                                <label
                                                  htmlFor={`speakingProficiency${index}`}
                                                  className="form-label"
                                                >
                                                  Speaking Proficiency
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  id={`speakingProficiency${index}`}
                                                  name={`memberDetails.${index}.speakingProficiency`}
                                                  type="number"
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails &&
                                                    errors?.memberDetails[
                                                      index
                                                    ] &&
                                                    errors?.memberDetails[index]
                                                      ?.speakingProficiency &&
                                                    touched?.memberDetails &&
                                                    touched?.memberDetails[
                                                      index
                                                    ] &&
                                                    touched?.memberDetails[
                                                      index
                                                    ]?.speakingProficiency
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder=""
                                                />
                                                <ErrorMessage
                                                  name={`memberDetails.${index}.speakingProficiency`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                              <div className="col-md-2 mb-3 me-3">
                                                <label
                                                  htmlFor={`readingProficiency${index}`}
                                                  className="form-label"
                                                >
                                                  Reading Proficiency
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  id={`readingProficiency${index}`}
                                                  name={`memberDetails.${index}.readingProficiency`}
                                                  type="number"
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails &&
                                                    errors?.memberDetails[
                                                      index
                                                    ] &&
                                                    errors?.memberDetails[index]
                                                      ?.readingProficiency &&
                                                    touched?.memberDetails &&
                                                    touched?.memberDetails[
                                                      index
                                                    ] &&
                                                    touched?.memberDetails[
                                                      index
                                                    ]?.readingProficiency
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder=""
                                                />
                                                <ErrorMessage
                                                  name={`memberDetails.${index}.readingProficiency`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                              <div className="col-md-2 mb-3 me-3">
                                                <label
                                                  htmlFor={`writingProficiency${index}`}
                                                  className="form-label"
                                                >
                                                  Writing Proficiency
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  id={`writingProficiency${index}`}
                                                  name={`memberDetails.${index}.writingProficiency`}
                                                  type="number"
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails &&
                                                    errors?.memberDetails[
                                                      index
                                                    ] &&
                                                    errors?.memberDetails[index]
                                                      ?.writingProficiency &&
                                                    touched?.memberDetails &&
                                                    touched?.memberDetails[
                                                      index
                                                    ] &&
                                                    touched?.memberDetails[
                                                      index
                                                    ]?.writingProficiency
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder=""
                                                />
                                                <ErrorMessage
                                                  name={`memberDetails.${index}.writingProficiency`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                              <div className="col-md-2 mb-3 me-3">
                                                <label
                                                  htmlFor={`listeningProficiency${index}`}
                                                  className="form-label"
                                                >
                                                  Listening Proficiency
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  id={`listeningProficiency${index}`}
                                                  name={`memberDetails.${index}.listeningProficiency`}
                                                  type="number"
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails &&
                                                    errors?.memberDetails[
                                                      index
                                                    ] &&
                                                    errors?.memberDetails[index]
                                                      ?.listeningProficiency &&
                                                    touched?.memberDetails &&
                                                    touched?.memberDetails[
                                                      index
                                                    ] &&
                                                    touched?.memberDetails[
                                                      index
                                                    ]?.listeningProficiency
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder=""
                                                />
                                                <ErrorMessage
                                                  name={`memberDetails.${index}.listeningProficiency`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                            </div>
                                            <div>
                                              <div className="col-md-4 mb-3 me-3">
                                                <label
                                                  htmlFor={`childrenSchoolName${index}`}
                                                  className="form-label"
                                                >
                                                  Children School Name
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  id={`childrenSchoolName${index}`}
                                                  name={`memberDetails.${index}.childrenSchoolName`}
                                                  type="text"
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails &&
                                                    errors?.memberDetails[
                                                      index
                                                    ] &&
                                                    errors?.memberDetails[index]
                                                      ?.childrenSchoolName &&
                                                    touched?.memberDetails &&
                                                    touched?.memberDetails[
                                                      index
                                                    ] &&
                                                    touched?.memberDetails[
                                                      index
                                                    ]?.childrenSchoolName
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  placeholder=""
                                                />
                                                <ErrorMessage
                                                  name={`memberDetails.${index}.childrenSchoolName`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                              <div className="col-md-4 mb-3">
                                                <label
                                                  htmlFor={`childrenSchoolStartDate${index}`}
                                                  className="form-label"
                                                >
                                                  School Start Date
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                </label>
                                                {/* <Field
                                                  id={`childrenSchoolStartDate${index}`}
                                                  name={`memberDetails.${index}.childrenSchoolStartDate`}
                                                  placeholder="Enter date"
                                                  type="date"
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails &&
                                                    errors?.memberDetails[
                                                      index
                                                    ] &&
                                                    errors?.memberDetails[index]
                                                      ?.childrenSchoolStartDate &&
                                                    touched?.memberDetails &&
                                                    touched?.memberDetails[
                                                      index
                                                    ] &&
                                                    touched?.memberDetails[
                                                      index
                                                    ]?.childrenSchoolStartDate
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                /> */}
                                                <CustomDatePicker
                                                  className={
                                                    "form-control" +
                                                    (errors?.memberDetails?.[
                                                      index
                                                    ]
                                                      ?.childrenSchoolStartDate &&
                                                    touched?.memberDetails?.[
                                                      index
                                                    ]?.childrenSchoolStartDate
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                  selected={
                                                    values?.memberDetails?.[
                                                      index
                                                    ]?.childrenSchoolStartDate
                                                      ? values.memberDetails?.[
                                                          index
                                                        ]
                                                          ?.childrenSchoolStartDate
                                                      : null
                                                  }
                                                  onChange={date => {
                                                    if (date) {
                                                      setFieldValue(
                                                        `memberDetails.${index}.childrenSchoolStartDate`,
                                                        date
                                                      );
                                                    } else {
                                                      setFieldValue(
                                                        `memberDetails.${index}.childrenSchoolStartDate`,
                                                        null
                                                      );
                                                    }
                                                  }}
                                                  placeholderText="YYYY-MM-DD"
                                                />
                                                <ErrorMessage
                                                  name={`memberDetails.${index}.childrenSchoolStartDate`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                              <div className="col-md-2 mb-3 me-3">
                                                <label
                                                  htmlFor={`childrenSchoolSupplies${index}`}
                                                  className="form-label"
                                                >
                                                  Children School Supplies
                                                  <span className="text-danger">
                                                    {" "}
                                                    *
                                                  </span>
                                                </label>
                                                <Field
                                                  id={`childrenSchoolSupplies${index}`}
                                                  name={`memberDetails.${index}.childrenSchoolSupplies`}
                                                  type="checkbox"
                                                  className={
                                                    "form-check-input" +
                                                    (errors?.memberDetails &&
                                                    errors?.memberDetails[
                                                      index
                                                    ] &&
                                                    errors?.memberDetails[index]
                                                      ?.childrenSchoolSupplies &&
                                                    touched?.memberDetails &&
                                                    touched?.memberDetails[
                                                      index
                                                    ] &&
                                                    touched?.memberDetails[
                                                      index
                                                    ]?.childrenSchoolSupplies
                                                      ? " is-invalid"
                                                      : "")
                                                  }
                                                />
                                                <ErrorMessage
                                                  name={`memberDetails.${index}.childrenSchoolSupplies`}
                                                  component="div"
                                                  className="invalid-feedback"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          {index <
                                          values?.memberSelect?.length - 1 ? (
                                            <hr />
                                          ) : null}
                                        </div>
                                      )
                                    )}
                                </div>
                              )}
                            </FieldArray>
                          </div>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md="3">
                          <h4>Reference Note</h4>
                        </Col>
                        <Col md="4">
                          <div className="mb-3">
                            <Label for="referenceNote" className="form-label">
                              Reference Note
                            </Label>
                            <Field
                              id="referenceNote"
                              name="referenceNote"
                              as="textarea"
                              className={
                                "form-control" +
                                (errors?.referenceNote && touched?.referenceNote
                                  ? " is-invalid"
                                  : "")
                              }
                              rows={5}
                              placeholder=""
                            />
                            <ErrorMessage
                              name="referenceNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Education));
