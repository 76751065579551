import React from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import { Form, Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { axiosPatch, axiosGet } from "helpers/api_helpers";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  formKey,
  formStatus,
  status,
  subFormKeyIntake,
} from "constants/constants";
import Select from "react-select";
import {
  setShowPageLoader,
  togglePersonalInfo,
  toggleIntakeEducationalBackground,
  toggleIntakeEmploymentHistory,
  toggleIntakeHealthInfo,
  toggleIntakeHousingSection,
  toggleIntakeImmigrationInfo,
} from "store/actions";
import CustomDatePicker from "components/Common/DateField";

class IntakeEmploymentHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allMembers: [],
    };
  }

  componentDidMount = async () => {
    const { clientData, referenceNote } = this.props;
    try {
      const leadClientData = {
        memberHouseholdId: clientData.memberHouseholdId,
        uci: clientData.uci,
        firstName: clientData.firstName,
        lastName: clientData.lastName,
        middleName: clientData.middleName,
        gender: clientData.gender,
        dateOfBirth: clientData.dateOfBirth,
        relation: "Self",
      };
      // Create array with lead client data and all members' data
      const allMembersData = [leadClientData, ...clientData.members];
      this.setState({ allMembers: allMembersData });
    } catch (error) {
      toast.error(error);
    }
  };

  handleSubmit = async values => {
    const {
      clientId,
      togglePersonalInfo,
      toggleIntakeHealthInfo,
      toggleIntakeImmigrationInfo,
      toggleIntakeEducationalBackground,
      toggleIntakeEmploymentHistory,
      personalInfo,
      intakeImmigrationInfo,
      intakeEmploymentHistory,
      intakeHealthInfo,
      intakeEducationalBackground,
      getClientData,
      setShowPageLoader,
    } = this.props;

    if (clientId) {
      try {
        setShowPageLoader(true);
        const requestBody = {
          type: {
            is_completed: true,
            form_key: formKey.INTAKE,
          },
          employmentInformation: {
            type: {
              subForm_key: subFormKeyIntake.EMPLOYMENT,
              formStatus: status.COMPLETED,
            },
            employmentDetailsMembers: values.employmentDetailsMembers.map(
              (employmentDetailsMember) => ({
                memberId: employmentDetailsMember.memberId,
                employmentHistory: employmentDetailsMember.employmentHistory.map(
                  (history) => ({
                    jobTitle: history.jobTitle,
                    company: history.company,
                    startDate: history.startDate,
                    endDate: history.endDate,
                  })
                ),
              })
            ),
            // notes: values.notes,
          },
        };
        const response = await axiosPatch(
          `/add-client/intake/${clientId}/employment`,
          {
            requestBody: requestBody,
            referenceNotes: values?.referenceNotes,
          }
        );
        if (response?.status) {
          setShowPageLoader(false);
          toast.success(response?.message || "Client Updated Successfully");
          getClientData();
          if (intakeEmploymentHistory) {
            toggleIntakeEmploymentHistory();
          }
          if (intakeEducationalBackground) {
            toggleIntakeEducationalBackground();
          }
          if (intakeHealthInfo) {
            toggleIntakeHealthInfo();
          }
          if (personalInfo) {
            togglePersonalInfo();
          }
          if (intakeImmigrationInfo) {
            toggleIntakeImmigrationInfo();
          }
        } else {
          setShowPageLoader(false);
          toast.error("handleSubmit API error", response?.message);
        }
      } catch (error) {
        setShowPageLoader(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          toast.error(`Server Error: ${error.response.data.message || error.response.statusText}`);
        } else if (error.request) {
          // The request was made but no response was received
          toast.error("Network Error: No response received from server");
        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error(`Error: ${error.message}`);
        }
        console.error("handleSubmit API error:", error);
      }
    } else {
      toast.error("Client ID is missing");
    }
  };

  render() {
    const { allMembers } = this.state;
    const { intakeData, referenceNote } = this.props;
    var refNote = "";
    if (referenceNote) {
      let latestNote = null;
      for (const note of referenceNote) {
        if (
          note.form === formKey.INTAKE &&
          note.subForm === subFormKeyIntake.EMPLOYMENT &&
          (!latestNote ||
            new Date(note.createdAt) > new Date(latestNote.createdAt))
        ) {
          latestNote = note;
        }
      }
      if (latestNote) {
        refNote = latestNote.note;
      }
    }
    const initialEmploymentHistory =
    intakeData?.employmentDetailsMembers
      ?.map(member => {
        const foundMember = allMembers.find(m => m.memberHouseholdId === member.memberId);
        if (foundMember) {
          return {
            ...member,
            memberName: `${foundMember.firstName || ""} ${
              foundMember.middleName || ""
            } ${foundMember.lastName || ""}`.trim(),
            employmentHistory: member.employmentHistory.map((history) => ({
              jobTitle: history.jobTitle,
              company: history.company,
              startDate: history.startDate,
              endDate: history.endDate,
            })),
          };

        }
        return null;
      })
      .filter(Boolean) || [];
    const initialValues = {
      employmentDetailsMembers: initialEmploymentHistory,
      referenceNotes: refNote || "",
    };
    const validationSchema = Yup.object().shape({
      employmentDetailsMembers: Yup.array().of(
        Yup.object().shape({
          employmentHistory: Yup.array().of(
            Yup.object().shape({
              jobTitle: Yup.string().required("Job title is required"),
              company: Yup.string().required("Company is required"),
              startDate: Yup.date().required("Start date is required"),
              endDate: Yup.date()
                .required("End date is required")
                .test(
                  "is-greater-than-start-date",
                  "End date must be later than start date",
                  function (endDate) {
                    const startDate = this.parent.startDate;
                    return startDate && endDate && endDate > startDate;
                  }
                ),
            })
          ),
        })
      ),
      referenceNotes: Yup.string("Notes can be in string"),
    });

    return (
      <React.Fragment>
        <div>
          <Card>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  this.handleSubmit(values);
                  actions.setSubmitting(false);
                }}
              >
                {({ errors, touched, values, setFieldValue, dirty }) => (
                  <Form>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Employment History Details</h4>
                        </Col>
                        <Col md="9">
                          <div className="col-md-6 mb-3">
                            <Label>Select members</Label>
                            <Select
                              id="restrictionDropdown"
                              name="restrictionDropdown"
                              options={allMembers.map(member => ({
                                value: member.memberHouseholdId,
                                label: `${member.firstName || ""} ${
                                  member.middleName || ""
                                } ${member.lastName || ""}`.trim(),
                              }))}
                              isMulti
                              value={values.employmentDetailsMembers.map(
                                member => ({
                                  value: member.memberId,
                                  label: member.memberName,
                                })
                              )}
                              onChange={selectedOption => {
                                const selectedValues = selectedOption.map(
                                  option => {
                                    const existingMember =
                                      values.employmentDetailsMembers.find(
                                        restriction =>
                                          restriction.memberId === option.value
                                      );
                                      if (existingMember) {
                                        return existingMember;
                                      }
                                    return {
                                      memberId: option.value,
                                      memberName: option.label,
                                      employmentHistory: [],
                                    };
                                  }
                                );
                                setFieldValue(
                                  "employmentDetailsMembers",
                                  selectedValues
                                );
                              }}
                              className="mb-2"
                              placeholder="Select members"
                            />
                            <Row>
                              {values?.employmentDetailsMembers?.map(
                                (employmentDetailsMember, index) => (
                                  <div
                                    key={`${index}-${employmentDetailsMember.memberId}`}
                                  >
                                    <Row>
                                      <h5 className="form-label mb-2">
                                        {employmentDetailsMember.memberName}{" "}
                                      </h5>
                                      <Label className="form-label">
                                        Recent employment history
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Col md="12">
                                        <FieldArray
                                          name={`employmentDetailsMembers[${index}].employmentHistory`}
                                        >
                                          {({ push, remove }) => (
                                            <>
                                              {employmentDetailsMember?.employmentHistory?.map(
                                                (
                                                  employment,
                                                  employmentIndex
                                                ) => (
                                                  <div
                                                    key={`${employmentIndex}`}
                                                  >
                                                    <div className="d-flex gap-4">
                                                      <div className="col-md-10">
                                                        <Label className="form-label fw-normal">
                                                          Job title
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </Label>
                                                        <Field
                                                          type="text"
                                                          name={`employmentDetailsMembers[${index}].employmentHistory[${employmentIndex}].jobTitle`}
                                                          className={
                                                            "form-control" +
                                                            (errors
                                                              .employmentDetailsMembers?.[
                                                              index
                                                            ]
                                                              ?.employmentHistory?.[
                                                              employmentIndex
                                                            ]?.jobTitle &&
                                                            touched
                                                              .employmentDetailsMembers?.[
                                                              index
                                                            ]
                                                              ?.employmentHistory?.[
                                                              employmentIndex
                                                            ]?.jobTitle
                                                              ? " is-invalid"
                                                              : "")
                                                          }
                                                          placeholder="Enter the job title"
                                                        />
                                                        <ErrorMessage
                                                          name={`employmentDetailsMembers[${index}].employmentHistory[${employmentIndex}].jobTitle`}
                                                          component="div"
                                                          className="invalid-feedback text-danger"
                                                        />
                                                      </div>
                                                      <div className="col-md-10">
                                                        <Label className="form-label fw-normal">
                                                          Company
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </Label>
                                                        <Field
                                                          type="text"
                                                          name={`employmentDetailsMembers[${index}].employmentHistory[${employmentIndex}].company`}
                                                          className={
                                                            "form-control" +
                                                            (errors
                                                              .employmentDetailsMembers?.[
                                                              index
                                                            ]
                                                              ?.employmentHistory?.[
                                                              employmentIndex
                                                            ]?.company &&
                                                            touched
                                                              .employmentDetailsMembers?.[
                                                              index
                                                            ]
                                                              ?.employmentHistory?.[
                                                              employmentIndex
                                                            ]?.company
                                                              ? " is-invalid"
                                                              : "")
                                                          }
                                                          placeholder="Enter the company"
                                                        />
                                                        <ErrorMessage
                                                          name={`employmentDetailsMembers[${index}].employmentHistory[${employmentIndex}].company`}
                                                          component="div"
                                                          className="invalid-feedback text-danger"
                                                        />
                                                      </div>
                                                    </div>

                                                    <Row className="mt-2">
                                                      <Col md="6">
                                                        <Label className="form-label fw-normal">
                                                          Start date
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </Label>
                                                        <CustomDatePicker
                                                          className={
                                                            "form-control" +
                                                            (errors
                                                              ?.employmentDetailsMembers?.[
                                                              index
                                                            ]
                                                              ?.employmentHistory?.[
                                                              employmentIndex
                                                            ]?.startDate &&
                                                            touched
                                                              ?.employmentDetailsMembers?.[
                                                              index
                                                            ]
                                                              ?.employmentHistory?.[
                                                              employmentIndex
                                                            ]?.startDate
                                                              ? " is-invalid"
                                                              : "")
                                                          }
                                                          selected={
                                                            values
                                                              ?.employmentDetailsMembers?.[
                                                              index
                                                            ]
                                                              ?.employmentHistory?.[
                                                              employmentIndex
                                                            ]?.startDate
                                                              ? values
                                                                  ?.employmentDetailsMembers?.[
                                                                  index
                                                                ]
                                                                  ?.employmentHistory?.[
                                                                  employmentIndex
                                                                ]?.startDate
                                                              : null
                                                          }
                                                          onChange={date => {
                                                            if (date) {
                                                              setFieldValue(
                                                                `employmentDetailsMembers[${index}].employmentHistory[${employmentIndex}].startDate`,
                                                                date
                                                              );
                                                            } else {
                                                              setFieldValue(
                                                                `employmentDetailsMembers[${index}].employmentHistory[${employmentIndex}].startDate`,
                                                                null
                                                              );
                                                            }
                                                          }}
                                                          placeholderText="YYYY-MM-DD"
                                                          max={new Date()}
                                                        />
                                                        <ErrorMessage
                                                          name={`employmentDetailsMembers[${index}].employmentHistory[${employmentIndex}].startDate`}
                                                          component="div"
                                                          className="invalid-feedback text-danger"
                                                        />
                                                      </Col>
                                                      <Col
                                                        className="mb-1 "
                                                        md="6"
                                                      >
                                                        <Label className="form-label fw-normal">
                                                          End date
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </Label>
                                                        <CustomDatePicker
                                                          className={
                                                            "form-control" +
                                                            (errors
                                                              ?.employmentDetailsMembers?.[
                                                              index
                                                            ]
                                                              ?.employmentHistory?.[
                                                              employmentIndex
                                                            ]?.endDate &&
                                                            touched
                                                              ?.employmentDetailsMembers?.[
                                                              index
                                                            ]
                                                              ?.employmentHistory?.[
                                                              employmentIndex
                                                            ]?.endDate
                                                              ? " is-invalid"
                                                              : "")
                                                          }
                                                          selected={
                                                            values
                                                              ?.employmentDetailsMembers?.[
                                                              index
                                                            ]
                                                              ?.employmentHistory?.[
                                                              employmentIndex
                                                            ]?.endDate
                                                              ? values
                                                                  .employmentDetailsMembers?.[
                                                                  index
                                                                ]
                                                                  ?.employmentHistory?.[
                                                                  employmentIndex
                                                                ]?.endDate
                                                              : null
                                                          }
                                                          disabled={
                                                            !values
                                                              .employmentDetailsMembers?.[
                                                              index
                                                            ]
                                                              ?.employmentHistory?.[
                                                              employmentIndex
                                                            ]?.startDate
                                                          }
                                                          onChange={date => {
                                                            if (date) {
                                                              setFieldValue(
                                                                `employmentDetailsMembers[${index}].employmentHistory[${employmentIndex}].endDate`,
                                                                date
                                                              );
                                                            } else {
                                                              setFieldValue(
                                                                `employmentDetailsMembers[${index}].employmentHistory[${employmentIndex}].endDate`,
                                                                null
                                                              );
                                                            }
                                                          }}
                                                          placeholderText="YYYY-MM-DD"
                                                          min={
                                                            new Date(
                                                              values.employmentDetailsMembers?.[
                                                                index
                                                              ]?.employmentHistory?.[
                                                                employmentIndex
                                                              ]?.startDate
                                                            )
                                                          }
                                                        />
                                                        <ErrorMessage
                                                          name={`employmentDetailsMembers[${index}].employmentHistory[${employmentIndex}].endDate`}
                                                          component="div"
                                                          className="invalid-feedback text-danger"
                                                        />
                                                      </Col>
                                                      <div className="">
                                                        <button
                                                          type="button"
                                                          className="btn btn-danger btn-md col-md-2 mt-1 p-1 mb-2"
                                                          onClick={() =>
                                                            remove(
                                                              employmentIndex
                                                            )
                                                          }
                                                        >
                                                          Remove
                                                        </button>
                                                      </div>
                                                    </Row>
                                                  </div>
                                                )
                                              )}
                                              <div className="ml-2">
                                                <button
                                                  type="button"
                                                  className="btn btn-primary btn-md p-1 mt-2 col-md-2 ml-2"
                                                  onClick={() =>
                                                    push({
                                                      jobTitle: "",
                                                      company: "",
                                                      startDate: "",
                                                      endDate: "",
                                                    })
                                                  }
                                                >
                                                  <i className="mdi mdi-text-box-plus me-1" />
                                                  Add Employement History
                                                </button>
                                              </div>
                                            </>
                                          )}
                                        </FieldArray>
                                      </Col>
                                    </Row>
                                    {index !==
                                      values.employmentDetailsMembers.length -
                                        1 && (
                                      <div className="full-width-hr"></div>
                                    )}
                                  </div>
                                )
                              )}
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <hr></hr>
                    <div>
                      <Row>
                        <Col md="3">
                          <h4>Reference Notes</h4>
                        </Col>
                        <Col md="9">
                          <Row>
                            <div className="col-md-6 mb-3">
                              <Field
                                name="referenceNotes"
                                as="textarea"
                                className={
                                  "form-control" +
                                  (errors.referenceNotes &&
                                  touched.referenceNotes
                                    ? " is-invalid"
                                    : "")
                                }
                                style={{ resize: "none" }}
                                placeholder="Enter any notes or references"
                              />
                              <ErrorMessage
                                name="referenceNotes"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
                          <Button type="submit" color="primary">
                            {intakeData?.type?.formStatus ===
                            formStatus.COMPLETED
                              ? dirty
                                ? "Save"
                                : "Edit"
                              : "Save & Next"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  personalInfo: state.Clients.personalInfo,
  intakeImmigrationInfo: state.Clients.intakeImmigrationInfo,
  intakeHealthInfo: state.Clients.intakeHealthInfo,
  intakeEducationalBackground: state.Clients.intakeEducationalBackground,
  intakeEmploymentHistory: state.Clients.intakeEmploymentHistory,
  intakeHousingSection: state.Clients.intakeHousingSection,
});

const mapDispatchToProps = dispatch => ({
  togglePersonalInfo: () => dispatch(togglePersonalInfo()),
  toggleIntakeImmigrationInfo: () => dispatch(toggleIntakeImmigrationInfo()),
  toggleIntakeHealthInfo: () => dispatch(toggleIntakeHealthInfo()),
  toggleIntakeEducationalBackground: () =>
    dispatch(toggleIntakeEducationalBackground()),
  toggleIntakeEmploymentHistory: () =>
    dispatch(toggleIntakeEmploymentHistory()),
  toggleIntakeHousingSection: () => dispatch(toggleIntakeHousingSection()),
  setShowPageLoader: bool => dispatch(setShowPageLoader(bool)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IntakeEmploymentHistory));
